import { createHelpers, createResource } from '@amfintech/refine-react-admin';

import { CollectionBankAccount } from '~/api';

import { resourceNames } from './resource-names';

const { defineFields, defineShowPage, defineCardSection } = createHelpers<CollectionBankAccount>({
  resourceName: resourceNames.collectionBankAccount,
});

export const collectionBankAccountResource = createResource({
  name: resourceNames.collectionBankAccount,
  label: 'Collection Bank Account',
  meta: {
    hide: true,
  },
  fields: defineFields([
    'id',
    'bankName',
    'accountNo',
    'beneficiaryName',
    'swiftCode',
    'currency',
    'createdAt',
    'updatedAt',
  ]),
  defaultValues: {},
  columns: () => [],
  show: defineShowPage({
    sections: [
      defineCardSection({
        title: 'Details',
        fields: [
          'bankName',
          'accountNo',
          'beneficiaryName', 
          'swiftCode',
          'currency',
        ],
      }),
    ],
  }),
});
