import { StatusField } from '@amfintech/react-admin-ui';
import { createHelpers, createResource, defineCustomPage } from '@amfintech/refine-react-admin';
import dayjs from 'dayjs';

import { AccountType, TomswinSync, TomswinSyncStatus, User } from '~/api';
import Dashboard from '~/pages/dashboard';
import DeveloperToolsDashboard from '~/pages/developer-tools/developer-tools-dashboard';

import { resourceNames } from './resource-names';

const { defineFields, defineShowPage, defineCardSection } = createHelpers({
  resourceName: resourceNames.developerTools,
});

export const developerToolsResource = createResource({
  name: resourceNames.developerTools,
  label: 'Dashboard',
  fields: [],
  defaultValues: {},
  columns: [] as any,
  list: defineCustomPage({
    title: 'Dashboard 2',
    render: () => <DeveloperToolsDashboard />,
  }),
});
