import * as React from 'react';
import { HiOutlineEye } from 'react-icons/hi';

import { Button, formatDate, StatusField, Switch } from '@amfintech/react-admin-ui';
import { createHelpers, createResource, Form, sentenceCase } from '@amfintech/refine-react-admin';

import {
  Fund,
  FundCategories,
  FundManagers,
  FundRiskProfile,
  Maybe,
  ModificationEntityType,
} from '~/api';
import { FundDocumentTypes } from '~/common/enums';

import { resourceNames } from './resource-names';

const formatFundRiskProfile = (riskProfile: FundRiskProfile) => {
  switch (riskProfile) {
    case FundRiskProfile.Low:
      return 'Low';
    case FundRiskProfile.LowMedium:
      return 'Low to Medium';
    case FundRiskProfile.Medium:
      return 'Medium';
    case FundRiskProfile.MediumHigh:
      return 'Medium to High';
    case FundRiskProfile.High:
      return 'High';
    default:
      return 'Unknown';
  }
};

const formatFundManager = (manager: Maybe<FundManagers>) => {
  switch (manager) {
    case FundManagers.Afm:
      return 'AmFunds Management Sdn Bhd';
    case FundManagers.Aifm:
      return 'AmIslamic Fund Management Sdn Bhd';
    default:
      return 'Unknown';
  }
};

const { defineFields, defineShowPage, defineCardSection, defineRelatedResourceSection } =
  createHelpers<Fund>({
    resourceName: resourceNames.fund,
  });

export const formatFundInfoStatus = (status: string) => {
  switch (status) {
    case 'Active':
      return 'success';
    case 'Inactive':
      return 'danger';
    default:
      return 'pending';
  }
};

export const fundInfoResource = createResource({
  name: resourceNames.fund,
  label: 'Fund Info',
  defaultValues: {
    fundName: '',
    fundCode: '',
    fundSize: 0,
    totalUnitInCirculation: 0,
  },
  fields: defineFields([
    'id',
    'fundName',
    'fundCode',
    'shariahFlag',
    'esgFlag',
    'localFlag',
    'navPrice',
    'collectionBankAccountId',
    'salesChargeDisplay',
    'switchingFeeDisplay',
    'annualManagementFeeDisplay',
    'trusteeFeeDisplay',
    {
      fundPolicy: [
        'id',
        'minInitInvestment',
        'minSubsequentInvestment',
        'minRedemptionUnit',
        'minHoldingUnit',
        'minSwitchingUnit',
        'currencyCode',
        'wholeSaleFlag',
        'salesChargePercent',
        'annualManagementFee',
        'annualTrusteeFee',
        'cutOffTime',
      ],
    },
    { fundPrices: ['price', 'date'] },
    { collectionBankAccount: ['beneficiaryName'] },
    {
      trustee: [
        'name',
        'contactNo',
        'faxNo',
        'website',
        'email',
        'registeredAddress',
        'businessAddress',
      ],
    },
    'launchDate',
    'launchPrice',
    'financialYearEnd',
    'fundFocusFlag',
    'manager',
    'fundSize',
    'fundSizeCurrency',
    'fundSizeDate',
    { fundDocuments: ['id', 'displayName', 'documentLink'] },
    'totalUnitInCirculation',
    'totalUnitInCirculationDate',
    'annualExpenseRatio',
    'annualExpenseRatioDate',
    'fundRiskProfile',
    'pricingBasic',
    'distributionPolicy',
    'investmentObj',
    'status',
    'trusteeId',
    'pricingBasic',
    'category',
    'lipperMyClass',
    'benchmark',
    'switchFee',
    'assetAllocation',
    'createdAt',
    'updatedAt',
  ]),
  allowDownload: true,
  list: {
    filters: {
      collapsible: 'expand-by-default',
    },
    makerChecker: {
      entityTypes: [ModificationEntityType.Fund],
    },
    downloadConfig: {
      category: (value: string) => {
        return value
          .split('_')
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
          .join(' ');
      },
      navPrice: (value: string) => {
        return Number(value).toLocaleString('en-MY', {
          minimumFractionDigits: 4,
          maximumFractionDigits: 4,
        });
      },
    },
  },
  controls: {
    sections: [
      {
        title: 'Basic Fund Information',
        items: [
          'fundName',
          'fundCode',
          'fundPolicy.wholeSaleFlag',
          'fundPolicy.currencyCode',
          'launchDate',
          'launchPrice',
          'category',
          'fundFocusFlag',
          'localFlag',
          'shariahFlag',
          'esgFlag',
          'manager',
        ],
      },
      {
        title: 'Investment Requirement',
        items: [
          'fundPolicy.minInitInvestment',
          'fundPolicy.minSubsequentInvestment',
          'fundPolicy.minRedemptionUnit',
          'fundPolicy.minHoldingUnit',
          'fundPolicy.minSwitchingUnit',
        ],
      },
      {
        title: 'Operational Details',
        items: ['fundPolicy.cutOffTime', 'financialYearEnd', 'distributionPolicy', 'pricingBasic'],
      },
      {
        title: 'Risk and Performance',
        items: ['fundRiskProfile', 'benchmark', 'lipperMyClass', 'assetAllocation'],
      },
      {
        title: 'Objective and Policies',
        items: ['investmentObj'],
      },
      {
        title: 'Trustee Information',
        items: ['trusteeId'],
      },
      {
        title: 'Fees and Charges',
        items: [
          'fundPolicy.salesChargePercent',
          'salesChargeDisplay',
          'fundPolicy.annualManagementFee',
          'annualManagementFeeDisplay',
          'fundPolicy.annualTrusteeFee',
          'trusteeFeeDisplay',
          'switchFee',
          'switchingFeeDisplay',
          'collectionBankAccountId',
        ],
      },
      {
        title: 'Others',
        items: [
          'fundSize',
          'fundSizeCurrency',
          'fundSizeDate',
          'totalUnitInCirculation',
          'totalUnitInCirculationDate',
          'annualExpenseRatio',
          'annualExpenseRatioDate',
        ],
      },
      {
        title: 'Fund Documents',
        items: ['fundDocuments'],
      },
    ],
    components: {
      fundName: {
        type: 'text',
        config: {
          label: 'Fund Name',
          required: true,
        },
      },
      fundCode: {
        type: 'text',
        config: {
          label: 'Fund Code',
          required: true,
        },
      },
      'fundPolicy.wholeSaleFlag': {
        type: 'switch',
        config: {
          label: 'Wholesale Indicator',
          required: true,
        },
      },
      'fundPolicy.currencyCode': {
        type: 'text',
        config: {
          label: 'Currency Code',
          required: true,
        },
      },
      launchDate: {
        type: 'date',
        config: {
          label: 'Launch Date',
          required: true,
        },
      },
      launchPrice: {
        type: 'number',
        config: {
          label: 'Launch Price',
          required: true,
        },
      },
      category: {
        type: 'select',
        config: {
          label: 'Fund Category',
          required: true,
          options: Object.values(FundCategories)
            .filter((category) => category !== FundCategories.Unknown)
            .map((category) => ({ label: category, value: category })),
        },
      },
      fundFocusFlag: {
        type: 'switch',
        config: {
          label: 'Fund Focus Indicator',
          required: true,
        },
      },
      localFlag: {
        type: 'select',
        config: {
          label: 'Geographical Location',
          valueType: 'boolean',
          required: true,
          options: [
            { label: 'Local', value: 'true' },
            { label: 'Global', value: 'false' },
          ],
        },
      },
      shariahFlag: {
        type: 'switch',
        config: {
          label: 'Shariah',
          required: true,
        },
      },
      esgFlag: {
        type: 'switch',
        config: {
          label: 'ESG',
          required: true,
        },
      },
      manager: {
        type: 'select',
        config: {
          label: 'Manager',
          required: true,
          options: Object.values(FundManagers).map((manager) => ({
            label: formatFundManager(manager),
            value: manager,
          })),
        },
      },
      'fundPolicy.minInitInvestment': {
        type: 'number',
        config: {
          label: 'Min Initial Investment',
          required: true,
        },
      },
      'fundPolicy.minSubsequentInvestment': {
        type: 'number',
        config: {
          label: 'Min Subsequent Investment',
          required: true,
        },
      },
      'fundPolicy.minRedemptionUnit': {
        type: 'number',
        config: {
          label: 'Min Redemption Unit',
          required: true,
        },
      },
      'fundPolicy.minHoldingUnit': {
        type: 'number',
        config: {
          label: 'Min Holding Unit',
          required: true,
        },
      },
      'fundPolicy.minSwitchingUnit': {
        type: 'number',
        config: {
          label: 'Min Switching Unit',
          required: true,
        },
      },
      'fundPolicy.cutOffTime': {
        type: 'text',
        config: {
          label: 'Cut Off Time',
          required: true,
        },
      },
      financialYearEnd: {
        type: 'text',
        config: {
          label: 'Financial Year End',
          required: false,
        },
      },
      distributionPolicy: {
        type: 'text',
        config: {
          label: 'Distribution Type / Policy',
          required: true,
        },
      },
      pricingBasic: {
        type: 'text',
        config: {
          label: 'Pricing Basic',
          required: true,
        },
      },
      fundRiskProfile: {
        type: 'select',
        config: {
          label: 'Fund Risk Profile',
          required: true,
          options: Object.values(FundRiskProfile).map((riskProfile) => ({
            label: formatFundRiskProfile(riskProfile),
            value: riskProfile,
          })),
        },
      },
      benchmark: {
        type: 'text',
        config: {
          label: 'Benchmark',
          required: true,
        },
      },
      lipperMyClass: {
        type: 'text',
        config: {
          label: 'Lipper Malaysian Classification',
          required: true,
        },
      },
      assetAllocation: {
        type: 'text',
        config: {
          label: 'Asset Allocation',
          required: true,
        },
      },
      investmentObj: {
        type: 'textarea',
        config: {
          label: 'Investment Objective',
          required: true,
        },
      },
      trusteeId: {
        type: 'resource-select',
        config: {
          label: 'Trustee Name',
          resourceName: resourceNames.trustee,
          optionLabel: 'name',
          optionValue: 'id',
          fields: ['id', 'name'],
          required: true,
        },
      },
      'fundPolicy.salesChargePercent': {
        type: 'number',
        config: {
          label: 'Sales Charges',
          required: true,
        },
      },
      salesChargeDisplay: {
        type: 'text',
        config: {
          label: 'Sales Charges Display',
          required: false,
        },
      },
      'fundPolicy.annualManagementFee': {
        type: 'number',
        config: {
          label: 'Annual Management Fee',
          required: true,
        },
      },
      annualManagementFeeDisplay: {
        type: 'text',
        config: {
          label: 'Annual Management Fee Display',
          required: false,
        },
      },
      'fundPolicy.annualTrusteeFee': {
        type: 'number',
        config: {
          label: 'Trustee Fee',
          required: true,
        },
      },
      trusteeFeeDisplay: {
        type: 'text',
        config: {
          label: 'Trustee Fee Display',
          required: false,
        },
      },
      switchFee: {
        type: 'number',
        config: {
          label: 'Switch Fee',
          required: true,
        },
      },
      switchingFeeDisplay: {
        type: 'text',
        config: {
          label: 'Switching Fee Display',
          required: false,
        },
      },
      collectionBankAccountId: {
        type: 'resource-select',
        config: {
          label: 'Collection Bank Account Name',
          required: true,
          resourceName: resourceNames.collectionBankAccount,
          optionLabel: 'beneficiaryName',
          optionValue: 'id',
          fields: ['id', 'beneficiaryName'],
        },
      },
      fundSize: {
        type: 'number',
        config: {
          label: 'Fund Size',
          required: true,
        },
      },
      fundSizeCurrency: {
        type: 'text',
        config: {
          label: 'Fund Size Currency',
          required: true,
        },
      },
      fundSizeDate: {
        type: 'date',
        config: {
          label: 'Fund Size Date',
          required: false,
        },
      },
      totalUnitInCirculation: {
        type: 'number',
        config: {
          label: 'Total Unit In Circulation',
          required: true,
        },
      },
      totalUnitInCirculationDate: {
        type: 'number',
        config: {
          label: 'Total Unit In Circulation Date',
          required: false,
        },
      },
      annualExpenseRatio: {
        type: 'number',
        config: {
          label: 'Annual Expense Ratio',
          required: true,
        },
      },
      annualExpenseRatioDate: {
        type: 'date',
        config: {
          label: 'Annual Expense Ratio Date',
          required: false,
        },
      },
      fundDocuments: {
        type: 'custom',
        config: {
          label: '',
          span: 2,
          hideLabel: true,
          required: false,
          render: (field) => {
            const [documents, setDocuments] = React.useState(
              field.value ||
                Object.values(FundDocumentTypes).map((type) => ({
                  displayName: type,
                  documentLink: '',
                }))
            );
            React.useEffect(() => {
              if (field.value?.length) {
                setDocuments(field.value);
              } else {
                field.onChange(documents);
              }
            }, [field.value]);

            const handleChange = (index: number, value: string) => {
              const updatedDocuments = [...documents];
              updatedDocuments[index] = {
                ...updatedDocuments[index],
                documentLink: value,
              };
              setDocuments(updatedDocuments);
              field.onChange(updatedDocuments);
            };
            return (
              <div className="w-full flex flex-col gap-4">
                {documents.map((value: any, index: number) => (
                  <div key={index}>
                    <Form.TextField
                      hideLabel={true}
                      hidden={true}
                      name={`fundDocuments.${index}.displayName`}
                      label="Display Name"
                      layout="horizontal"
                      defaultValue={value.displayName}
                    />

                    <Form.TextField
                      name={`fundDocuments.${index}.documentLink`}
                      label={value.displayName}
                      placeholder="Document Link"
                      layout="horizontal"
                      defaultValue={value.documentLink}
                      onChange={(e) => handleChange(index, e.target.value)}
                    />
                  </div>
                ))}
              </div>
            );
          },
        },
      },
    },
  },
  columns: ({ LinkToDetails }) => [
    {
      id: 'fundName',
      header: 'Fund Name',
      accessorKey: 'fundName',
      cell: (data) => (
        <LinkToDetails resourceId={data.row.original.id} className="font-semibold">
          {data.cell.getValue<string>()}
        </LinkToDetails>
      ),
    },
    {
      id: 'fundCode',
      header: 'Fund Code',
      accessorKey: 'fundCode',
    },
    {
      id: 'category',
      header: 'Category',
      accessorKey: 'category',
      cell: (data) => {
        const rawValue = data.cell.getValue<string>();

        const formattedValue = rawValue.split('_').join(' ').toUpperCase();
        return formattedValue;
      },
    },
    {
      id: 'fundPolicy.currencyCode',
      header: 'Currency',
      accessorKey: 'fundPolicy.currencyCode',
    },
    {
      id: 'navPrice',
      header: 'Net Asset Value (NAV)',
      accessorKey: 'navPrice',
      cell: (data) =>
        Number(data.cell.getValue<number>()).toLocaleString('en-MY', {
          minimumFractionDigits: 4,
          maximumFractionDigits: 4,
        }),
    },
    {
      id: 'status',
      header: 'Status',
      accessorKey: 'status',
      cell: (data) => {
        const status = data.cell.getValue<string>();
        return <StatusField status={formatFundInfoStatus(status)} label={status} />;
      },
    },
  ],
  filterControls: {
    fundName: {
      type: 'text',
      config: {
        label: 'Fund Name',
      },
      operator: 'contains',
    },
    fundCode: {
      type: 'text',
      config: {
        label: 'Fund Code',
      },
      operator: 'contains',
    },
    status: {
      type: 'select',
      config: {
        label: 'Status',
        options: [
          { label: 'Active', value: 'Active' },
          { label: 'Inactive', value: 'Inactive' },
        ],
      },
      operator: 'eq',
    },
  },
  defaultSorter: [{ field: 'createdAt', order: 'desc' }],
  show: defineShowPage({
    sections: [
      defineCardSection({
        title: 'Basic Fund Information',
        collapsible: 'expand-by-default',
        fields: [
          'fundName',
          'fundCode',
          { fundPolicy: ['wholeSaleFlag', 'currencyCode'] },
          'launchDate',
          'launchPrice',
          'category',
          'fundFocusFlag',
          'localFlag',
          'shariahFlag',
          'esgFlag',
          'manager',
        ],
        displays: {
          'fundPolicy.wholeSaleFlag': {
            label: 'Wholesale Indicator',
            render: (data: Fund, modifications) => {
              if (!modifications) {
                return <Switch checked={data.fundPolicy?.wholeSaleFlag} disabled />;
              }

              const { oldValues, newValues, ModificationDiff } = modifications;
              return (
                <ModificationDiff
                  oldValue={oldValues?.wholeSaleFlag === true ? 'Yes' : 'No'}
                  newValue={newValues?.wholeSaleFlag === true ? 'Yes' : 'No'}
                />
              );
            },
          },
          'fundPolicy.currencyCode': {
            label: 'Currency',
          },
          category: {
            label: 'Fund Category',
            render: (data: Fund, modifications) => {
              if (!modifications) {
                return sentenceCase(data.category);
              }

              const { oldValues, newValues, ModificationDiff } = modifications;
              return (
                <ModificationDiff
                  oldValue={sentenceCase(oldValues?.category)}
                  newValue={sentenceCase(newValues?.category)}
                />
              );
            },
          },
          localFlag: {
            label: 'Geographical Location',
            render: (data: Fund, modifications) => {
              if (!modifications) {
                return data.localFlag ? 'Local' : 'Global';
              }

              const { oldValues, newValues, ModificationDiff } = modifications;
              return (
                <ModificationDiff
                  oldValue={oldValues?.localFlag ? 'Local' : 'Global'}
                  newValue={newValues?.localFlag ? 'Local' : 'Global'}
                />
              );
            },
          },
          fundFocusFlag: {
            label: 'Fund Focus Indicator',
            render: (data: Fund, modifications) => {
              if (!modifications) {
                return <Switch checked={data.fundFocusFlag} disabled />;
              }

              const { oldValues, newValues, ModificationDiff } = modifications;
              return (
                <ModificationDiff
                  oldValue={oldValues?.fundFocusFlag === true ? 'Yes' : 'No'}
                  newValue={newValues?.fundFocusFlag === true ? 'Yes' : 'No'}
                />
              );
            },
          },
          shariahFlag: {
            label: 'Shariah',
            render: (data: Fund, modifications) => {
              if (!modifications) {
                return <Switch checked={data.shariahFlag} disabled />;
              }

              const { oldValues, newValues, ModificationDiff } = modifications;
              return (
                <ModificationDiff
                  oldValue={oldValues?.shariahFlag === true ? 'Yes' : 'No'}
                  newValue={newValues?.shariahFlag === true ? 'Yes' : 'No'}
                />
              );
            },
          },
          esgFlag: {
            label: 'ESG Flag',
            render: (data: Fund, modifications) => {
              if (!modifications) {
                return <Switch checked={data.esgFlag} disabled />;
              }

              const { oldValues, newValues, ModificationDiff } = modifications;
              return (
                <ModificationDiff
                  oldValue={oldValues?.esgFlag === true ? 'Yes' : 'No'}
                  newValue={newValues?.esgFlag === true ? 'Yes' : 'No'}
                />
              );
            },
          },
          manager: {
            label: 'Manager',
            render: (data: Fund, modifications) => {
              if (!modifications) {
                return formatFundManager(data.manager ?? null);
              }

              const { oldValues, newValues, ModificationDiff } = modifications;
              return (
                <ModificationDiff
                  oldValue={formatFundManager(oldValues?.manager ?? null)}
                  newValue={formatFundManager(newValues?.manager ?? null)}
                />
              );
            },
          },
        },
      }),
      defineCardSection({
        title: 'Investment Requirement',
        collapsible: 'expand-by-default',
        fields: [
          {
            fundPolicy: [
              'minInitInvestment',
              'minSubsequentInvestment',
              'minRedemptionUnit',
              'minHoldingUnit',
              'minSwitchingUnit',
            ],
          },
        ],
        displays: {
          'fundPolicy.minInitInvestment': {
            label: 'Min Initial Investment',
            render: (data: Fund) =>
              Number(data.fundPolicy?.minInitInvestment)?.toLocaleString('en-MY'),
          },
          'fundPolicy.minSubsequentInvestment': {
            label: 'Min Subsequent Investment',
            render: (data: Fund) =>
              Number(data.fundPolicy?.minSubsequentInvestment)?.toLocaleString('en-MY'),
          },
          'fundPolicy.minRedemptionUnit': {
            label: 'Min Redemption Unit',
            render: (data: Fund) =>
              Number(data.fundPolicy?.minRedemptionUnit)?.toLocaleString('en-MY'),
          },
          'fundPolicy.minHoldingUnit': {
            label: 'Min Holding Unit',
            render: (data: Fund) =>
              Number(data.fundPolicy?.minHoldingUnit)?.toLocaleString('en-MY'),
          },
          'fundPolicy.minSwitchingUnit': {
            label: 'Min Switching Unit',
            render: (data: Fund) =>
              Number(data.fundPolicy?.minSwitchingUnit)?.toLocaleString('en-MY'),
          },
        },
      }),
      defineCardSection({
        title: 'Operational Details',
        collapsible: 'expand-by-default',
        fields: [
          { fundPolicy: ['cutOffTime'] },
          'financialYearEnd',
          'distributionPolicy',
          'pricingBasic',
        ],
        displays: {
          'fundPolicy.cutOffTime': {
            label: 'Cut Off Time',
            render: (data: Fund) => data.fundPolicy?.cutOffTime,
          },
          distributionPolicy: {
            label: 'Distribution Type / Policy',
            render: (data: Fund, modifications) => {
              if (!modifications) {
                return data.distributionPolicy;
              }

              const { oldValues, newValues, ModificationDiff } = modifications;
              return (
                <ModificationDiff
                  oldValue={oldValues?.distributionPolicy}
                  newValue={newValues?.distributionPolicy}
                />
              );
            },
          },
          pricingBasic: {
            label: 'Pricing Basic',
            render: (data: Fund, modifications) => {
              if (!modifications) {
                return data.pricingBasic;
              }

              const { oldValues, newValues, ModificationDiff } = modifications;
              return (
                <ModificationDiff
                  oldValue={oldValues?.pricingBasic}
                  newValue={newValues?.pricingBasic}
                />
              );
            },
          },
        },
      }),
      defineCardSection({
        title: 'Risk and Performance',
        collapsible: 'expand-by-default',
        fields: ['fundRiskProfile', 'benchmark', 'lipperMyClass', 'assetAllocation'],
        displays: {
          lipperMyClass: {
            label: 'Lipper Malaysian Classification',
            render: (data: Fund, modifications) => {
              if (!modifications) {
                return data.lipperMyClass;
              }

              const { oldValues, newValues, ModificationDiff } = modifications;
              return (
                <ModificationDiff
                  oldValue={oldValues?.lipperMyClass}
                  newValue={newValues?.lipperMyClass}
                />
              );
            },
          },
          fundRiskProfile: {
            label: 'Fund Risk Profile',
            render: (data: Fund, modifications) => {
              if (!modifications) {
                return formatFundRiskProfile(data.fundRiskProfile);
              }

              const { oldValues, newValues, ModificationDiff } = modifications;
              return (
                <ModificationDiff
                  oldValue={formatFundRiskProfile(oldValues?.fundRiskProfile)}
                  newValue={formatFundRiskProfile(newValues?.fundRiskProfile)}
                />
              );
            },
          },
        },
      }),
      defineCardSection({
        title: 'Objective and Policies',
        collapsible: 'expand-by-default',
        fields: ['investmentObj'],
        displays: {
          investmentObj: {
            label: 'Investment Objective',
            render: (data: Fund, modifications) => {
              if (!modifications) {
                return data.investmentObj;
              }

              const { oldValues, newValues, ModificationDiff } = modifications;
              return (
                <ModificationDiff
                  oldValue={oldValues?.investmentObj}
                  newValue={newValues?.investmentObj}
                />
              );
            },
          },
        },
      }),
      defineCardSection({
        title: 'Trustee Information',
        collapsible: 'expand-by-default',
        fields: [
          {
            trustee: [
              'name',
              'contactNo',
              'faxNo',
              'website',
              'email',
              'registeredAddress',
              'businessAddress',
            ],
          },
        ],
        displays: {
          'trustee.name': {
            label: 'Trustee Name',
            render: (data: Fund, modifications) => {
              if (!modifications) {
                return data.trustee?.name;
              }

              return (
                <modifications.ModificationDiff
                  oldValue={modifications.oldValues.trusteeId}
                  newValue={modifications.newValues.trusteeId}
                  getModifiedResource={{
                    resourceName: resourceNames.trustee,
                    id: modifications.newValues.trusteeId,
                    field: 'name',
                  }}
                />
              );
            },
          },
          'trustee.contactNo': {
            label: 'Trustee Contact No',
            render: (data: Fund, modifications) => {
              if (!modifications) {
                return data.trustee?.contactNo;
              }

              return (
                <modifications.ModificationDiff
                  oldValue={modifications.oldValues.trusteeId}
                  newValue={modifications.newValues.trusteeId}
                  getModifiedResource={{
                    resourceName: resourceNames.trustee,
                    id: modifications.newValues.trusteeId,
                    field: 'contactNo',
                  }}
                />
              );
            },
          },
          'trustee.faxNo': {
            label: 'Trustee Fax No',
            render: (data: Fund, modifications) => {
              if (!modifications) {
                return data.trustee?.faxNo;
              }

              return (
                <modifications.ModificationDiff
                  oldValue={modifications.oldValues.trusteeId}
                  newValue={modifications.newValues.trusteeId}
                  getModifiedResource={{
                    resourceName: resourceNames.trustee,
                    id: modifications.newValues.trusteeId,
                    field: 'faxNo',
                  }}
                />
              );
            },
          },
          'trustee.website': {
            label: 'Trustee Website',
            render: (data: Fund, modifications) => {
              if (!modifications) {
                return data.trustee?.website;
              }

              return (
                <modifications.ModificationDiff
                  oldValue={modifications.oldValues.trusteeId}
                  newValue={modifications.newValues.trusteeId}
                  getModifiedResource={{
                    resourceName: resourceNames.trustee,
                    id: modifications.newValues.trusteeId,
                    field: 'website',
                  }}
                />
              );
            },
          },
          'trustee.email': {
            label: 'Trustee Email',
            render: (data: Fund, modifications) => {
              if (!modifications) {
                return data.trustee?.email;
              }

              return (
                <modifications.ModificationDiff
                  oldValue={modifications.oldValues.trusteeId}
                  newValue={modifications.newValues.trusteeId}
                  getModifiedResource={{
                    resourceName: resourceNames.trustee,
                    id: modifications.newValues.trusteeId,
                    field: 'email',
                  }}
                />
              );
            },
          },
          'trustee.registeredAddress': {
            label: 'Trustee Registered Address',
            render: (data: Fund, modifications) => {
              if (!modifications) {
                return data.trustee?.registeredAddress;
              }

              return (
                <modifications.ModificationDiff
                  oldValue={modifications.oldValues.trusteeId}
                  newValue={modifications.newValues.trusteeId}
                  getModifiedResource={{
                    resourceName: resourceNames.trustee,
                    id: modifications.newValues.trusteeId,
                    field: 'registeredAddress',
                  }}
                />
              );
            },
          },
          'trustee.businessAddress': {
            label: 'Trustee Business Address',
            render: (data: Fund, modifications) => {
              if (!modifications) {
                return data.trustee?.businessAddress;
              }

              return (
                <modifications.ModificationDiff
                  oldValue={modifications.oldValues.trusteeId}
                  newValue={modifications.newValues.trusteeId}
                  getModifiedResource={{
                    resourceName: resourceNames.trustee,
                    id: modifications.newValues.trusteeId,
                    field: 'businessAddress',
                  }}
                />
              );
            },
          },
        },
      }),
      defineCardSection({
        title: 'Fees and Charges',
        collapsible: 'expand-by-default',
        fields: [
          'salesChargeDisplay',
          'switchingFeeDisplay',
          'annualManagementFeeDisplay',
          'trusteeFeeDisplay',
          {
            fundPolicy: ['salesChargePercent', 'annualManagementFee', 'annualTrusteeFee'],
          },
          {
            collectionBankAccount: ['beneficiaryName', 'accountNo'],
          },
        ],
        displays: {
          'fundPolicy.salesChargePercent': {
            label: 'Sales Charges',
            render: (data: Fund) => {
              const salesChargePercent = data.fundPolicy?.salesChargePercent;
              if (salesChargePercent == null || salesChargePercent === '') {
                return 'Nil';
              }
              return `Up to ${Number(salesChargePercent).toLocaleString('en-MY', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}%`;
            },
          },
          'fundPolicy.annualManagementFee': {
            label: 'Annual Management Fee',
            render: (data: Fund) => {
              const annualManagementFee = data.fundPolicy?.annualManagementFee;
              if (annualManagementFee == null || annualManagementFee === '') {
                return 'Nil';
              }
              return `${Number(annualManagementFee).toLocaleString('en-MY', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}%`;
            },
          },
          'fundPolicy.annualTrusteeFee': {
            label: 'Trustee Fee',
            render: (data: Fund) => {
              const annualTrusteeFee = data.fundPolicy?.annualTrusteeFee;
              if (annualTrusteeFee == null || annualTrusteeFee === '') {
                return 'Nil';
              }
              return `${Number(annualTrusteeFee).toLocaleString('en-MY', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}%`;
            },
          },
          switchFee: {
            label: 'Switch Fee',
            render: (data: Fund, modifications) => {
              const switchFee = data.switchFee;
              if (!modifications && (switchFee == null || switchFee === '')) {
                return 'Nil';
              }

              if (!modifications) {
                return Number(switchFee).toLocaleString('en-MY', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                });
              }

              const { oldValues, newValues, ModificationDiff } = modifications;
              return (
                <ModificationDiff
                  oldValue={Number(oldValues?.switchFee).toLocaleString('en-MY', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                  newValue={Number(newValues?.switchFee).toLocaleString('en-MY', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                />
              );
            },
          },
          'collectionBankAccount.beneficiaryName': {
            label: 'Collection Bank Account Name',
            render: (data: Fund, modifications) => {
              if (!modifications) {
                return data.collectionBankAccount?.beneficiaryName;
              }

              return (
                <modifications.ModificationDiff
                  oldValue={modifications.oldValues.collectionBankAccountId}
                  newValue={modifications.newValues.collectionBankAccountId}
                  getModifiedResource={{
                    resourceName: resourceNames.collectionBankAccount,
                    id: modifications.newValues.collectionBankAccountId,
                    field: 'beneficiaryName',
                  }}
                />
              );
            },
          },
          'collectionBankAccount.accountNo': {
            label: 'Collection Bank Account Number',
            render: (data: Fund, modifications) => {
              if (!modifications) {
                return data.collectionBankAccount?.accountNo;
              }

              return (
                <modifications.ModificationDiff
                  oldValue={modifications.oldValues.collectionBankAccountId}
                  newValue={modifications.newValues.collectionBankAccountId}
                  getModifiedResource={{
                    resourceName: resourceNames.collectionBankAccount,
                    id: modifications.newValues.collectionBankAccountId,
                    field: 'accountNo',
                  }}
                />
              );
            },
          },
        },
      }),
      defineCardSection({
        title: 'Others',
        collapsible: 'expand-by-default',
        fields: [
          'navPrice',
          'fundSize',
          'fundSizeCurrency',
          'fundSizeDate',
          'totalUnitInCirculation',
          'totalUnitInCirculationDate',
          'annualExpenseRatio',
          'annualExpenseRatioDate',
        ],
        displays: {
          navPrice: {
            label: 'Net Asset Value (NAV)',
            render: (data: Fund) =>
              Number(data.navPrice)?.toLocaleString('en-MY', {
                minimumFractionDigits: 4,
                maximumFractionDigits: 4,
              }),
          },
          fundSize: {
            label: 'Fund Size',
            render: (data: Fund, modifications) => {
              if (!modifications) {
                return Number(data.fundSize)?.toLocaleString('en-MY', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                });
              }

              const { oldValues, newValues, ModificationDiff } = modifications;
              return (
                <ModificationDiff
                  oldValue={Number(oldValues?.fundSize).toLocaleString('en-MY', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                  newValue={Number(newValues?.fundSize).toLocaleString('en-MY', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                />
              );
            },
          },
          totalUnitInCirculation: {
            label: 'Total Unit In Circulation',
            render: (data: Fund, modifications) => {
              if (!modifications) {
                return Number(data.totalUnitInCirculation)?.toLocaleString('en-MY');
              }

              const { oldValues, newValues, ModificationDiff } = modifications;
              return (
                <ModificationDiff
                  oldValue={Number(oldValues?.totalUnitInCirculation).toLocaleString('en-MY')}
                  newValue={Number(newValues?.totalUnitInCirculation).toLocaleString('en-MY')}
                />
              );
            },
          },
          totalUnitInCirculationDate: {
            label: 'Total Unit In Circulation Date',
            render: (data: Fund, modifications) => {
              if (!data.totalUnitInCirculationDate) {
                return '';
              }

              if (!modifications) {
                return formatDate(data.totalUnitInCirculationDate, { formatType: 'dateOnly' });
              }

              const { oldValues, newValues, ModificationDiff } = modifications;
              return (
                <ModificationDiff
                  oldValue={formatDate(oldValues?.totalUnitInCirculationDate, {
                    formatType: 'dateOnly',
                  })}
                  newValue={formatDate(newValues?.totalUnitInCirculationDate, {
                    formatType: 'dateOnly',
                  })}
                />
              );
            },
          },
          annualExpenseRatioDate: {
            label: 'Annual Expense Ratio Date',
            render: (data: Fund, modifications) => {
              if (!data.annualExpenseRatioDate) {
                return '';
              }

              if (!modifications) {
                return formatDate(data.annualExpenseRatioDate, { formatType: 'dateOnly' });
              }

              const { oldValues, newValues, ModificationDiff } = modifications;
              return (
                <ModificationDiff
                  oldValue={formatDate(oldValues?.annualExpenseRatioDate, {
                    formatType: 'dateOnly',
                  })}
                  newValue={formatDate(newValues?.annualExpenseRatioDate, {
                    formatType: 'dateOnly',
                  })}
                />
              );
            },
          },
          fundSizeDate: {
            label: 'Fund Size Date',
            render: (data: Fund, modifications) => {
              if (!data.fundSizeDate) {
                return '';
              }

              if (!modifications) {
                return formatDate(data.fundSizeDate, { formatType: 'dateOnly' });
              }

              const { oldValues, newValues, ModificationDiff } = modifications;
              return (
                <ModificationDiff
                  oldValue={formatDate(oldValues?.fundSizeDate, { formatType: 'dateOnly' })}
                  newValue={formatDate(newValues?.fundSizeDate, { formatType: 'dateOnly' })}
                />
              );
            },
          },
        },
      }),
      defineRelatedResourceSection({
        title: 'Fund Documents',
        relatedResourceName: 'FundDocument',
        relatedResourceFields: ['id', 'displayName', 'documentLink'],
        fields: ['id', 'fundCode'],
        filterConfig: {
          collapsible: 'expand-by-default',
        },
        hidePagination: true,
        getFilterConfig: ({ resource }) => ({
          permanent: [
            {
              field: 'fundCode',
              operator: 'eq',
              value: resource?.fundCode,
            },
          ],
        }),
        columns: ({ modifications }) => [
          {
            id: 'displayName',
            header: 'Display Name',
            accessorKey: 'displayName',
          },
          {
            id: 'documentLink',
            header: 'Document Link',
            accessorKey: 'documentLink',
            cell: (props) => {
              if (!modifications?.newValues?.fundDocuments) {
                return <>{props.getValue()}</>;
              }

              const { id, documentLink } = props.row.original;
              const newValues = modifications?.newValues?.fundDocuments?.updateMany || [];

              const matchingDoc = newValues.find((doc: any) => doc.where.id === id);
              if (matchingDoc && matchingDoc.data.documentLink !== documentLink) {
                return (
                  <modifications.ModificationDiff
                    oldValue={props.getValue()}
                    newValue={matchingDoc?.data?.documentLink}
                  />
                );
              }

              return <>{props.getValue()}</>;
            },
          },
        ],
      }),
    ],
  }),
});
