import * as React from 'react';

import { useCustomMutation, useInvalidate, useNavigation, useNotification } from '@refinedev/core';

import { resourceNames } from '~/resources/resource-names';

export const useSendInvitationCodeMutation = () => {
  const { open } = useNotification();
  const customMutation = useCustomMutation();
  const invalidate = useInvalidate();
  const { list: navigateToList } = useNavigation();

  const mutate = React.useCallback((userId?: string) => {
    customMutation.mutate(
      {
        method: 'post',
        url: '',
        values: {},
        metaData: {
          operation: 'sendInvitationCodeToUser',
          fields: ['invitationCode'],
          variables: {
            userId: {
              type: 'String',
              required: true,
              value: userId,
            },
          },
        },
      },
      {
        onSuccess: () => {
          open &&
            open({
              key: `${userId}${new Date().getTime()}`,
              type: 'success',
              message: 'Invitation code sent to user',
            });

          invalidate({
            resource: resourceNames.user,
            invalidates: ['list'],
          });
        },
      }
    );
  }, []);

  return {
    ...customMutation,
    mutate,
  };
};
