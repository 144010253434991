import { ImageViewer, StatusField } from '@amfintech/react-admin-ui';
import { createHelpers, createResource } from '@amfintech/refine-react-admin';
import { useOne } from '@refinedev/core';

import {
  AccountType,
  ConsentStatus,
  ConsentType,
  ProfileConsent
} from '~/api';

import { resourceNames } from './resource-names';

const { defineFields, defineCardSection, defineShowPage } = createHelpers<ProfileConsent>({
  resourceName: resourceNames.profileConsent,
});

export const formatConsentStatus = (status: ConsentStatus) => {
  switch (status) {
    case ConsentStatus.Active:
      return 'success';
    case ConsentStatus.Revoked:
      return 'danger';
    default:
      return 'pending';
  }
};

export const profileConsentResource = createResource({
  name: resourceNames.profileConsent,
  label: 'Profile Consents',
  fields: defineFields([
    'id',
    'issuerProfileId',
    {
      issuerProfile: ['id', 'type', 'companyName', 'fullName'],
    },
    'recipientProfileId',
    {
      recipientProfile: ['id', 'type', 'companyName', 'fullName'],
    },
    'approvedEmail',
    'consentType',
    'status',
    'createdAt',
    'updatedAt',
  ]),
  defaultValues: {},
  list: {
    downloadConfig: {
      recipientProfile: (value: any) => {
        return value?.type === 'Corporate' ? value?.companyName : value?.fullName;
      },
      issuerProfile: (value: any) => {
        return value?.type === 'Corporate' ? value?.companyName : value?.fullName;
      },
    },
    filters: {
      collapsible: 'expand-by-default',
    },
  },
  meta: {
    hide: true,
  },
  filterControls: {
    consentType: {
      type: 'select',
      config: {
        label: 'Consent Type',
        options: Object.entries(ConsentType).map(([key, value]) => ({
          label: key,
          value: value,
        })),
      },
      operator: 'eq',
    },
    status: {
      type: 'select',
      config: {
        label: 'Status',
        options: Object.entries(ConsentStatus).map(([key, value]) => ({
          label: key,
          value: value,
        })),
      },
      operator: 'eq',
    },
    approvedEmail: {
      type: 'text',
      config: {
        label: 'Approved Email',
      },
      operator: 'contains',
    },
  },
  allowCreate: false,
  allowDownload: true,
  defaultSorter: [{ field: 'createdAt', order: 'desc' }],
  columns: ({ LinkToDetails }) => [
    {
      id: 'recipientProfile',
      header: 'Recipient',
      accessorKey: 'recipientProfile',
      cell: (data) => {
        const profile = data.cell.getValue<ProfileConsent['recipientProfile']>();
        const name = profile?.type === 'Corporate' ? profile?.companyName : profile?.fullName;
        return (
          <LinkToDetails className="font-semibold" resourceId={data.row.original.id}>
            {name}
          </LinkToDetails>
        );
      },
    },
    {
      id: 'approvedEmail',
      header: 'Approved Email',
      accessorKey: 'approvedEmail',
    },
    {
      id: 'issuerProfile',
      header: 'Issuer',
      accessorKey: 'issuerProfile',
      cell: (data) => {
        const profile = data.cell.getValue<ProfileConsent['issuerProfile']>();
        const name = profile?.type === 'Corporate' ? profile?.companyName : profile?.fullName;
        return name;
      },
    },
    {
      id: 'consentType',
      header: 'Consent Type',
      accessorKey: 'consentType',
    },
    {
      id: 'status',
      header: 'Status',
      accessorKey: 'status',
      cell: (data) => {
        const status = data.cell.getValue<ConsentStatus>();
        return <StatusField status={formatConsentStatus(status)} label={status} />;
      },
    },
  ],
  allowEdit: false,
  allowDelete: false,
  show: defineShowPage({
    makerChecker: {
      redirectToResource: resourceNames.customer,
    },
    sections: [
      defineCardSection({
        title: 'Consent Details',
        collapsible: 'expand-by-default',
        fields: [
          { issuerProfile: ['type', 'companyName', 'fullName', 'idNo'] },
          { recipientProfile: ['type', 'companyName', 'fullName', 'idNo'] },
          'approvedEmail',
          'consentType',
          'status',
          'createdAt',
          'updatedAt',
        ],
        displays: {
          issuerProfile: {
            label: 'Issuer',
            render: (data, modifications) => {
              const profile = data.issuerProfile ?? {
                type: AccountType.Corporate,
                companyName: '-',
                fullName: '-',
              };
              const field = profile?.type === AccountType.Corporate ? 'companyName' : 'fullName';
              const name = profile?.[field];

              if (modifications) {
                return (
                  <modifications.ModificationDiff
                    oldValue={modifications.oldValues.issuerProfile?.id}
                    newValue={modifications.newValues.issuerProfile?.id}
                    getModifiedResource={{
                      resourceName: resourceNames.profile,
                      id: modifications.newValues.issuerProfile.connect.id,
                      field,
                    }}
                  />
                );
              }

              return <span>{name}</span>;
            },
          },
          recipientProfile: {
            label: 'Recipient',
            render: (data, modifications) => {
              const profile = data.recipientProfile ?? {
                type: AccountType.Corporate,
                companyName: '-',
                fullName: '-',
              };
              const field = profile?.type === AccountType.Corporate ? 'companyName' : 'fullName';
              const name = profile?.[field];

              if (modifications) {
                return (
                  <modifications.ModificationDiff
                    oldValue={modifications.oldValues.recipientProfile?.id}
                    newValue={modifications.newValues.recipientProfile?.id}
                    getModifiedResource={{
                      resourceName: resourceNames.profile,
                      id: modifications.newValues.recipientProfile.connect.id,
                      field,
                    }}
                  />
                );
              }

              return <span>{name}</span>;
            },
          },
          approvedEmail: {
            label: 'Approved Email',
          },
          consentType: {
            label: 'Consent Type',
          },
          status: {
            label: 'Status',
          },
          createdAt: {
            label: 'Created At',
            type: 'date',
            formatType: 'dateAndTime',
          },
          updatedAt: {
            label: 'Updated At',
            type: 'date',
            formatType: 'dateAndTime',
          },
        },
        additionalDisplayItems: [
          {
            label: 'Issuer ID Number',
            position: {
              after: 'recipientProfile',
            },
            value: (data) => {
              return data.issuerProfile?.idNo;
            },
          },
          {
            label: 'Recipient ID Number',
            position: {
              after: 'recipientProfile',
            },
            value: (data) => {
              return data.recipientProfile?.idNo;
            },
          },
        ],
      }),
      defineCardSection({
        title: 'Attachment',
        collapsible: 'expand-by-default',
        fields: ['attachmentId'],
        displays: {
          attachmentId: {
            span: 2,
            label: '',
            render: (data: ProfileConsent, modifications) => {
              const ImageComponent = ({ id, alt }: { id: string; alt: string }) => {
                const { data: imageData } = useOne({
                  resource: resourceNames.document,
                  id,
                  meta: { fields: ['encodedData'] },
                });
                return imageData?.data ? (
                  <ImageViewer
                    src={imageData.data.encodedData}
                    alt={alt}
                    className="max-w-full max-h-[800px] object-contain rounded-md"
                  />
                ) : null;
              };

              if (!data.attachmentId && !modifications?.newValues?.attachmentId)
                return 'No attachment';

              return (
                <div className="flex flex-col gap-4 sm:flex-row sm:gap-8">
                  {modifications?.newValues?.attachmentId && (
                    <div>
                      <p className="font-semibold mb-2">New Attachment:</p>
                      <ImageComponent
                        id={modifications.newValues.attachmentId}
                        alt="New attachment image"
                      />
                    </div>
                  )}
                  {modifications?.oldValues?.attachmentId && (
                    <div>
                      <p className="font-semibold mb-2">Old Attachment:</p>
                      <ImageComponent
                        id={modifications.oldValues.attachmentId}
                        alt="Old attachment image"
                      />
                    </div>
                  )}
                  {!modifications?.newValues?.attachmentId &&
                    !modifications?.oldValues?.attachmentId &&
                    data.attachmentId && (
                      <ImageComponent id={data.attachmentId} alt="Attachment image" />
                    )}
                </div>
              );
            },
          },
        },
      }),
    ],
  }),
});
