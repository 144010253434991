import { createHelpers, createResource, sentenceCase } from '@amfintech/refine-react-admin';

import { AccountType, ModificationEntityType, Profile, Status } from '~/api';
import { ProfileShowPage } from '~/pages/profile/profile-show';

import { resourceNames } from './resource-names';

const { defineFields, createTableComponent, createResourceEditDialog, createResourceCreateButton } =
  createHelpers<Profile>({
    resourceName: resourceNames.profile,
  });

export const formatUserStatus = (status?: Status) => {
  switch (status) {
    case Status.Active:
      return 'success';
    case Status.Inactive:
      return 'pending';
    case Status.Closed:
      return 'danger';
    case Status.ProfileLocked:
      return 'warn';
    default:
      return 'pending';
  }
};

export const customerResource = createResource({
  identifier: resourceNames.customer,
  name: resourceNames.profile,
  label: 'CustomerProfiles',
  fields: defineFields([
    'id',
    'type',
    'fullName',
    'salutation',
    'phoneNo',
    'nationality',
    'idType',
    'idNo',
    'idExpiryDate',
    'dateOfBirth',
    'cityOfBirth',
    'countryOfBirth',
    'gender',
    'bumiputera',
    'race',
    'maritalStatus',
    'religion',
    'mothersMaidenName',
    'email',
    'permanentAddress',
    'mailingAddress',
    'city',
    'state',
    'postcode',
    'employmentStatus',
    'occupation',
    'jobIndustry',
    'profession',
    'companyName',
    'natureOfBusiness',
    'companyAddress',
    'officePhoneNo',
    'estimatedNetWorth',
    'annualIncome',
    'sourceOfFund',
    'purposeOfInvestment',
    'expectedInvestmentAmount',
    'frequencyOfTransaction',
    'politicalExposedPerson',
    'prominentRole',
    'taxResidency',
    'declaration',
    'safRating',
    'marketingPush',
    'amlSanctionScreeningPoint',
    'employmentType',
    'monthlyIncome',
    'sourceOfWealth',
    { users: ['id'] },
  ]),
  defaultValues: {},
  list: {
    filters: {
      required: true,
      collapsible: 'expand-by-default',
      initial: [
        {
          field: 'type',
          operator: 'eq',
          value: AccountType.Corporate,
        },
      ],
    },
    makerChecker: {
      entityTypes: [
        ModificationEntityType.User,
        ModificationEntityType.ProfileConsent,
        ModificationEntityType.AuthorisedSignatory,
      ],
    },
  },
  filterControls: {
    idNo: {
      type: 'text',
      config: {
        label: 'ID Number',
      },
      operator: 'contains',
    },
    type: {
      type: 'select',
      config: {
        label: 'Account Type',
        options: [
          { label: 'Individual', value: AccountType.Individual },
          { label: 'Corporate', value: AccountType.Corporate },
        ],
      },
      operator: 'eq',
    },
  },
  allowCreate: false,
  defaultSorter: [{ field: 'createdAt', order: 'desc' }],
  columns: ({ LinkToDetails }) => [
    {
      id: 'companyName',
      header: 'Company Name',
      accessorKey: 'companyName',
      cell: (data) => {
        // return sentenceCase(data.cell.getValue<string>());
        const companyName = data.cell.getValue<string>();

        return (
          <LinkToDetails resourceId={data.row.original.id} className="font-semibold">
            {companyName}
          </LinkToDetails>
        );
      },
    },
    {
      id: 'idNo',
      header: 'ID Number',
      accessorKey: 'idNo',
    },
    {
      id: 'fullName',
      header: 'User Full Name',
      accessorKey: 'fullName',
    },
    {
      id: 'email',
      header: 'User Email Address',
      accessorKey: 'email',
    },
  ],
  allowEdit: false,
  allowDelete: false,
  show: {
    title: 'Customer Profile',
    fields: [
      { accounts: ['masterAccountNo'] },
      'businessCategory',
      'businessCategoryDisplay',
      'createdAt',
      'companyAddress',
      'mailingAddress',
      'city',
      'state',
      'postcode',
      'marketingPush',
      'highNetWorth',
      'phoneNo',
      'email',
      'natureOfBusiness',
      'sourceOfFund',
      'sourceOfWealth',
      { bankAccounts: ['bankCode', 'beneficiaryName', 'accountNo', 'isDefault'] },
      'id',
      'idNo',
      'fullName',
      { users: ['status'] },
      'updatedAt',
    ],
    component: ({ queryResult }) => (
      <ProfileShowPage
        profile={queryResult?.data?.data}
        createTableComponent={createTableComponent}
        createResourceEditDialog={createResourceEditDialog}
        createResourceCreateButton={createResourceCreateButton}
      />
    ),
  },
});
