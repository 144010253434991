import * as React from 'react';
import { HiPlusCircle } from 'react-icons/hi';

import {
  Button,
  ButtonProps,
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from '@amfintech/react-admin-ui';
import {
  Content,
  CreateResourceEditPageOptions,
  CreateDataInput,
  ResourceTableConfig,
  CreateResourceEditDialogReturns,
  CreateResourceCreatePageOptions,
} from '@amfintech/refine-react-admin';

import { ConsentType, Profile, ProfileConsent, Status, User } from '~/api';
import { resourceNames } from '~/resources/resource-names';

import { ProfileAuthorisersPage } from './profile-authorisers';
import { ProfileConsentPage } from './profile-consent';
import { ProfileInfoPage } from './profile-info';
import { ProfileMaintenancePage } from './profile-maintenance';
import { ProfileTransactionsPage } from './profile-transactions';

export const ProfileShowPage = ({
  profile,
  createTableComponent,
  createResourceEditDialog,
  createResourceCreateButton,
}: {
  profile?: Profile;
  createTableComponent: <T extends object>(props: ResourceTableConfig<T>) => () => React.ReactNode;
  createResourceEditDialog: <T extends object, DefaultValues extends CreateDataInput<T>>(
    options: CreateResourceEditPageOptions<T, DefaultValues>
  ) => CreateResourceEditDialogReturns;
  createResourceCreateButton: <T extends object, DefaultValues extends CreateDataInput<T>>(
    options: CreateResourceCreatePageOptions<T, DefaultValues>
  ) => (props: ButtonProps) => React.ReactNode;
}) => {
  const [currentTab, setCurrentTab] = React.useState('personal');

  const ConsentCreateButton = createResourceCreateButton<
    ProfileConsent,
    CreateDataInput<ProfileConsent>
  >({
    resourceName: resourceNames.profileConsent,
    resourceLabel: 'Profile Consent',
    defaultValues: {
      recipientId: profile?.idNo,
    },
    controls: {
      components: {
        recipientId: {
          type: 'hidden',
        },
        issuerId: {
          type: 'text',
          config: {
            label: 'Company ID Number',
            placeholder: 'Enter company ID number',
          },
        },
        approvedEmail: {
          type: 'text',
          config: {
            label: 'Dedicated Email Address',
            placeholder: 'Enter dedicated email address',
          },
        },
        consentType: {
          type: 'select',
          config: {
            label: 'Consent Type',
            placeholder: 'Select consent type',
            options: Object.values(ConsentType).map((consentType) => ({
              label: consentType,
              value: consentType,
            })),
          },
        },
        attachment: {
          type: 'image-upload',
          config: {
            label: 'Attachment',
          },
        },
      },
    },
  });

  const [emailError, setEmailError] = React.useState<string | null>(null);

  const validateEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const MaintenanceCreateButton = createResourceCreateButton<User, CreateDataInput<User>>({
    resourceName: resourceNames.user,
    resourceLabel: 'New User',
    defaultValues: {
      ['profiles.connect.0.id']: profile?.id,
      status: Status.Inactive,
    },
    controls: {
      components: {
        name: {
          type: 'text',
          config: {
            label: 'Full Name',
            placeholder: 'Enter full name',
            required: true,
          },
        },
        email: {
          type: 'text',
          config: {
            label: 'Email Address',
            placeholder: 'Enter email address',
            required: true,
            onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
              const value = event.target.value;
              if (!validateEmail(value)) {
                setEmailError('Please enter a valid email address.');
              } else {
                setEmailError(null);
              }
            },
          },
        },
        phoneNo: {
          type: 'text',
          config: {
            label: 'Phone No',
            placeholder: 'Enter phone number',
          },
        },
        hpPhoneNo: {
          type: 'text',
          config: {
            label: 'HP Phone No',
            placeholder: 'Enter HP phone number',
          },
        },
        status: {
          type: 'hidden',
        },
        ['profiles.connect.0.id']: {
          type: 'hidden',
        },
      },
    },
  });

  const AuthorisedSignatoryCreateButton = createResourceCreateButton<User, CreateDataInput<User>>({
    resourceName: resourceNames.authorisedSignatory,
    resourceLabel: 'Authorised Signatory',
    bulkCreate: true,
    defaultValues: {
      companyProfileId: profile?.id,
    },
    controls: {
      components: {
        fullName: {
          type: 'text',
          config: {
            label: 'Full Name',
            required: true,
          },
        },
        email: {
          type: 'text',
          config: {
            label: 'Email Address',
            required: true,
          },
        },
        attachment: {
          type: 'image-upload',
          config: {
            label: 'Attachment',
            required: false,
          },
        },
        companyProfileId: {
          type: 'hidden',
        },
      },
    },
  });

  return (
    profile && (
      <div className="w-full">
        <Content
          title="Customer Profile"
          extra={
            currentTab === 'consent' ? (
              <ConsentCreateButton
                variant="solid"
                size="sm"
                className="min-w-[7rem]"
                icon={<HiPlusCircle />}
              />
            ) : currentTab === 'maintenance' ? (
              <MaintenanceCreateButton
                variant="solid"
                size="sm"
                className="min-w-[7rem]"
                icon={<HiPlusCircle />}
              />
            ) : currentTab === 'authorisers' ? (
              <AuthorisedSignatoryCreateButton
                variant="solid"
                size="sm"
                className="min-w-[7rem]"
                icon={<HiPlusCircle />}
              />
            ) : undefined
          }
        >
          <Tabs defaultValue="personal" onValueChange={setCurrentTab}>
            <TabsList>
              <TabsTrigger value="personal">Personal Information</TabsTrigger>
              <TabsTrigger value="maintenance">Maintenance</TabsTrigger>
              <TabsTrigger value="transactions">Transactions</TabsTrigger>
              <TabsTrigger value="authorisers">Authoriser List</TabsTrigger>
              <TabsTrigger value="consent">Profile Consent</TabsTrigger>
            </TabsList>

            <TabsContent value="personal">
              <ProfileInfoPage profile={profile} />
            </TabsContent>

            <TabsContent value="maintenance">
              <ProfileMaintenancePage
                createTableComponent={createTableComponent}
                createResourceEditDialog={createResourceEditDialog}
                profileId={profile?.id}
              />
            </TabsContent>

            <TabsContent value="transactions">
              <ProfileTransactionsPage
                createTableComponent={createTableComponent}
                profileId={profile?.id}
              />
            </TabsContent>

            <TabsContent value="authorisers">
              <ProfileAuthorisersPage
                createTableComponent={createTableComponent}
                profileId={profile?.id}
              />
            </TabsContent>

            <TabsContent value="consent">
              <ProfileConsentPage
                createTableComponent={createTableComponent}
                profileId={profile?.id}
              />
            </TabsContent>
          </Tabs>
        </Content>
      </div>
    )
  );
};
