import {
  Button,
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from '@amfintech/react-admin-ui';

import { Overview } from './overview';
import { RecentSales } from './recent-sales';

export const metadata = {
  title: 'Dashboard',
  description: 'Example dashboard app built using the components.',
};

export default function DashboardPage() {
  return (
    <div className="flex flex-col items-center justify-center h-screen p-8">
      <img
        src="/dashboard-placeholder.svg"
        alt="Dashboard Placeholder"
        className="w-72 h-72 mb-6"
      />
      <p className="text-center text-xl text-muted-foreground">
        AmInvest Backoffice platform helps you ensure a consistent data management.
      </p>
    </div>
  );
}

// export default function DashboardPage() {
//   return (
//     <div className="flex-col flex">
//       <div className="flex-1 space-y-4 p-8 pt-6">
//         <div className="flex items-center justify-between space-y-2">
//           <h2 className="text-3xl font-bold tracking-tight">Dashboard</h2>
//           <div className="flex items-center space-x-2">
//             <Button>Download</Button>
//           </div>
//         </div>
//         <Tabs defaultValue="overview" className="space-y-4">
//           <TabsList>
//             <TabsTrigger value="overview">Overview</TabsTrigger>
//             <TabsTrigger value="analytics" disabled>
//               Funds Performance
//             </TabsTrigger>
//             <TabsTrigger value="reports" disabled>
//               Reported Issue
//             </TabsTrigger>
//             <TabsTrigger value="notifications" disabled>
//               Customer Satisfaction
//             </TabsTrigger>
//           </TabsList>
//           <TabsContent value="overview" className="space-y-4">
//             <div className="grid gap-4 md:grid-cols-2 lg:grid-cols-4">
//               <Card>
//                 <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
//                   <CardTitle className="text-sm font-medium">Total Fund Purchase </CardTitle>
//                   <svg
//                     xmlns="http://www.w3.org/2000/svg"
//                     viewBox="0 0 24 24"
//                     fill="none"
//                     stroke="currentColor"
//                     strokeLinecap="round"
//                     strokeLinejoin="round"
//                     strokeWidth="2"
//                     className="h-4 w-4 text-muted-foreground"
//                   >
//                     <path d="M12 2v20M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6" />
//                   </svg>
//                 </CardHeader>
//                 <CardContent>
//                   <div className="text-2xl font-bold text-green-500">MYR 211,405,231.00</div>
//                   <p className="text-xs text-muted-foreground">321 requests received</p>
//                 </CardContent>
//               </Card>
//               <Card>
//                 <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
//                   <CardTitle className="text-sm font-medium">Total Fund Switching</CardTitle>
//                   <svg
//                     xmlns="http://www.w3.org/2000/svg"
//                     viewBox="0 0 24 24"
//                     fill="none"
//                     stroke="currentColor"
//                     strokeLinecap="round"
//                     strokeLinejoin="round"
//                     strokeWidth="2"
//                     className="h-4 w-4 text-muted-foreground"
//                   >
//                     <path d="M16 21v-2a4 4 0 0 0-4-4H6a4 4 0 0 0-4 4v2" />
//                     <circle cx="9" cy="7" r="4" />
//                     <path d="M22 21v-2a4 4 0 0 0-3-3.87M16 3.13a4 4 0 0 1 0 7.75" />
//                   </svg>
//                 </CardHeader>
//                 <CardContent>
//                   <div className="text-2xl font-bold">+ 12,350</div>
//                   <p className="text-xs text-muted-foreground">180 requests received</p>
//                 </CardContent>
//               </Card>
//               <Card>
//                 <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
//                   <CardTitle className="text-sm font-medium">Total Fund Redemption</CardTitle>
//                   <svg
//                     xmlns="http://www.w3.org/2000/svg"
//                     viewBox="0 0 24 24"
//                     fill="none"
//                     stroke="currentColor"
//                     strokeLinecap="round"
//                     strokeLinejoin="round"
//                     strokeWidth="2"
//                     className="h-4 w-4 text-muted-foreground"
//                   >
//                     <rect width="20" height="14" x="2" y="5" rx="2" />
//                     <path d="M2 10h20" />
//                   </svg>
//                 </CardHeader>
//                 <CardContent>
//                   <div className="text-2xl font-bold text-red-500">MYR 100,000,000</div>
//                   <p className="text-xs text-muted-foreground">35 requests received</p>
//                 </CardContent>
//               </Card>
//               <Card>
//                 <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
//                   <CardTitle className="text-sm font-medium">
//                     Leads Generated/ New Account Opening
//                   </CardTitle>
//                   <svg
//                     xmlns="http://www.w3.org/2000/svg"
//                     viewBox="0 0 24 24"
//                     fill="none"
//                     stroke="currentColor"
//                     strokeLinecap="round"
//                     strokeLinejoin="round"
//                     strokeWidth="2"
//                     className="h-4 w-4 text-muted-foreground"
//                   >
//                     <path d="M22 12h-4l-3 9L9 3l-3 9H2" />
//                   </svg>
//                 </CardHeader>
//                 <CardContent>
//                   <div className="text-2xl font-bold">+573</div>
//                   <p className="text-xs text-muted-foreground">As of today</p>
//                 </CardContent>
//               </Card>
//             </div>
//             <div className="grid gap-4 md:grid-cols-2 lg:grid-cols-7">
//               <Card className="col-span-4">
//                 <CardHeader>
//                   <CardTitle>Overview</CardTitle>
//                 </CardHeader>
//                 <CardContent className="pl-2">
//                   <Overview />
//                 </CardContent>
//               </Card>
//               <Card className="col-span-3">
//                 <CardHeader>
//                   <CardTitle>Recent Sales</CardTitle>
//                   <CardDescription>2,333 transaction requests received this month.</CardDescription>
//                 </CardHeader>
//                 <CardContent>
//                   <RecentSales />
//                 </CardContent>
//               </Card>
//             </div>
//           </TabsContent>
//         </Tabs>
//       </div>
//     </div>
//   );
// }
