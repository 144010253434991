import * as React from 'react';

import { formatDate, ImageViewer } from '@amfintech/react-admin-ui';
import { createHelpers, createResource } from '@amfintech/refine-react-admin';
import { useOne } from '@refinedev/core';

import { AuthorisedSignatory, ModificationEntityType } from '~/api';

import { resourceNames } from './resource-names';

const { defineFields, defineCardSection, defineShowPage } = createHelpers<AuthorisedSignatory>({
  resourceName: resourceNames.authorisedSignatory,
});

export const profileSignatoriesResource = createResource({
  name: resourceNames.authorisedSignatory,
  label: 'Profile Signatories',
  fields: defineFields([
    'id',
    'fullName',
    'email',
    'attachmentId',
    'companyProfileId',
    {
      companyProfile: ['id', 'type', 'companyName'],
    },
    'createdAt',
    'updatedAt',
  ]),
  meta: { hide: true },
  defaultValues: {},
  list: {
    filters: {
      collapsible: 'expand-by-default',
    },
  },
  filterControls: {
    fullName: {
      type: 'text',
      config: {
        label: 'Full Name',
      },
      operator: 'contains',
    },
    email: {
      type: 'text',
      config: {
        label: 'Email',
      },
      operator: 'contains',
    },
  },
  allowCreate: false,
  allowDownload: true,
  defaultSorter: [{ field: 'createdAt', order: 'desc' }],
  columns: ({ LinkToDetails }) => [
    {
      id: 'fullName',
      header: 'Full Name',
      accessorKey: 'fullName',
      cell: (data) => (
        <LinkToDetails className="font-semibold" resourceId={data.row.original.id}>
          {data.getValue()}
        </LinkToDetails>
      ),
    },
    {
      id: 'email',
      header: 'Email',
      accessorKey: 'email',
    },
    {
      id: 'companyProfile',
      header: 'Company',
      accessorKey: 'companyProfile',
      cell: (data) => {
        const profile = data.cell.getValue<AuthorisedSignatory['companyProfile']>();
        return profile?.companyName;
      },
    },
    {
      id: 'createdAt',
      header: 'Created At',
      accessorKey: 'createdAt',
      cell: (data) => formatDate(data.getValue<string>(), { formatType: 'dateAndTime' }),
    },
  ],
  allowEdit: false,
  allowDelete: false,
  show: defineShowPage({
    makerChecker: {
      redirectToResource: resourceNames.customer,
    },
    sections: [
      defineCardSection({
        title: 'Signatory Details',
        collapsible: 'expand-by-default',
        fields: [
          'fullName',
          'email',
          'companyProfileId',
          'status',
          { companyProfile: ['type', 'companyName'] },
          'createdAt',
          'updatedAt',
        ],
        displays: {
          fullName: {
            label: 'Full Name',
          },
          email: {
            label: 'Email',
          },
          companyProfile: {
            label: 'Company',
            hidden: true,
          },
          companyProfileId: {
            label: 'Company',
            render: (data, modifications) => {
              const profile = data.companyProfile;

              if (!modifications) {
                return <span>{profile?.companyName}</span>;
              }

              const { oldValues, newValues, ModificationDiff } = modifications;
              return (
                <ModificationDiff
                  oldValue={oldValues?.companyProfileId}
                  newValue={newValues?.companyProfileId}
                  getModifiedResource={{
                    resourceName: resourceNames.profile,
                    id: newValues?.companyProfileId,
                    field: 'companyName',
                  }}
                />
              );
            },
          },
          status: {
            label: 'Status',
            render: (data, modifications) => {
              if (!modifications) {
                return <span>{data.status}</span>;
              }

              const { oldValues, newValues, ModificationDiff } = modifications;
              return <ModificationDiff oldValue={oldValues?.status} newValue={newValues?.status} />;
            },
          },
          createdAt: {
            label: 'Created At',
            type: 'date',
            formatType: 'dateAndTime',
          },
          updatedAt: {
            label: 'Updated At',
            type: 'date',
            formatType: 'dateAndTime',
          },
        },
      }),
      defineCardSection({
        title: 'Attachment',
        collapsible: 'expand-by-default',
        fields: ['attachmentId'],
        displays: {
          attachmentId: {
            span: 2,
            label: '',
            render: (data: AuthorisedSignatory, modifications) => {
              const ImageComponent = ({ id, alt }: { id: string; alt: string }) => {
                const { data: imageData } = useOne({
                  resource: resourceNames.document,
                  id,
                  meta: { fields: ['encodedData'] },
                });
                return imageData?.data ? (
                  <ImageViewer
                    src={imageData.data.encodedData}
                    alt={alt}
                    className="max-w-full max-h-[800px] object-contain rounded-md"
                  />
                ) : null;
              };

              if (!data.attachmentId && !modifications?.newValues?.attachmentId) return 'No image';

              return (
                <div className="flex flex-col gap-4 sm:flex-row sm:gap-8">
                  {modifications?.newValues?.attachmentId && (
                    <div>
                      <p className="font-semibold mb-2">New Attachment:</p>
                      <ImageComponent
                        id={modifications.newValues.attachmentId}
                        alt="New attachment image"
                      />
                    </div>
                  )}
                  {modifications?.oldValues?.attachmentId && (
                    <div>
                      <p className="font-semibold mb-2">Old Attachment:</p>
                      <ImageComponent
                        id={modifications.oldValues.attachmentId}
                        alt="Old attachment image"
                      />
                    </div>
                  )}
                  {!modifications?.newValues?.attachmentId &&
                    !modifications?.oldValues?.attachmentId &&
                    data.attachmentId && (
                      <ImageComponent id={data.attachmentId} alt="Attachment image" />
                    )}
                </div>
              );
            },
          },
        },
      }),
    ],
  }),
});
