import { createHelpers, createResource } from '@amfintech/refine-react-admin';

import { Trustee } from '~/api';

import { resourceNames } from './resource-names';

const { defineFields, defineShowPage, defineCardSection } = createHelpers<Trustee>({
  resourceName: resourceNames.trustee,
});

export const trusteeResource = createResource({
  name: resourceNames.trustee,
  label: 'Trustee',
  fields: defineFields([
    'id',
    'name',
    'registeredAddress',
    'businessAddress',
    'email',
    'contactNo',
    'faxNo',
    'website',
    'createdAt',
    'updatedAt',
  ]),
  defaultValues: {},
  columns: () => [],
  meta: {
    hide: true,
  },
  show: defineShowPage({
    sections: [
      defineCardSection({
        title: 'Details',
        fields: [
          'name',
          'registeredAddress',
          'businessAddress',
          'email', 
          'contactNo',
          'faxNo',
          'website',
        ],
      }),
    ],
  }),
}); 