import React from 'react';

import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
  Skeleton,
} from '@amfintech/react-admin-ui';
import { Content } from '@amfintech/refine-react-admin';
import { useCustom } from '@refinedev/core';
import dayjs from 'dayjs';

export default function DeveloperToolsDashboard() {
  const { data: tomSwinSyncCurrentData, isLoading: tomSwinSyncCurrentLoading } = useCustom({
    method: 'get',
    url: '',
    metaData: {
      operation: 'tomswinSyncCurrent',
      fields: ['batchId', 'fileType', 'lastUpdatedAt'],
    },
    queryOptions: {
      refetchInterval: 5000,
    },
  });

  const notifications = [
    {
      batchId: '{some uuid}',
      fileType: 'FMD_NAV',
      lastUpdatedAt: '2024-01-01',
    },
  ];

  return (
    <div className="w-full">
      <Content title="Developer Tools">
        <div className="w-full grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1 xl:grid-cols-2 gap-4">
          <Card>
            <CardHeader>
              <CardTitle>Active Tomswin Sync</CardTitle>
              {!tomSwinSyncCurrentLoading && (
                <CardDescription>
                  Currently there are {tomSwinSyncCurrentData?.data?.length} active Tomswin Syncs
                </CardDescription>
              )}
            </CardHeader>
            <CardContent>
              {tomSwinSyncCurrentLoading ? (
                <Skeleton heightClass="h-5" width="medium" />
              ) : (
                <div>
                  {tomSwinSyncCurrentData?.data?.map((tomswinSync: any, index: any) => (
                    <div
                      key={index}
                      className="mb-4 grid grid-cols-[25px_1fr] items-start pb-4 last:mb-0 last:pb-0"
                    >
                      <span className="flex h-2 w-2 translate-y-1 rounded-full bg-sky-500" />
                      <div className="space-y-1">
                        <p className="text-sm font-medium leading-none">
                          {tomswinSync.batchId} - {tomswinSync.fileType}
                        </p>
                        <p className="text-sm text-muted-foreground">
                          Last updated at:{' '}
                          {dayjs(Number(tomswinSync.lastUpdatedAt)).format('YYYY-MM-DD HH:mm:ss')}
                        </p>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </CardContent>
          </Card>
        </div>
      </Content>
    </div>
  );
}
