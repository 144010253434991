import { useState } from 'react';
import { HiOutlineEye, HiOutlinePencil } from 'react-icons/hi';

import {
  Button,
  Dialog,
  DialogContent,
  DialogOverlay,
  DialogPortal,
  DialogTitle,
  DialogTrigger,
  StatusField,
} from '@amfintech/react-admin-ui';
import { DynamicForm, ResourceTableConfig } from '@amfintech/refine-react-admin';
import { useForm } from '@refinedev/react-hook-form';

import { AuthorisedSignatory, AuthorisedSignatoryStatus, CreationStatus } from '~/api';
import { resourceNames } from '~/resources/resource-names';

const formatStatus = (status: AuthorisedSignatoryStatus) => {
  switch (status) {
    case AuthorisedSignatoryStatus.Active:
      return 'success';
    case AuthorisedSignatoryStatus.Revoked:
      return 'danger';
    default:
      return 'pending';
  }
};
export const ProfileAuthorisersPage = ({
  createTableComponent,
  profileId,
}: {
  createTableComponent: <T extends object>(props: ResourceTableConfig<T>) => () => React.ReactNode;
  profileId?: string;
}) => {
  const tableComponent = createTableComponent<AuthorisedSignatory>({
    resource: resourceNames.authorisedSignatory,
    fields: ['id', 'fullName', 'email', 'attachment', 'status'],
    columns: () => [
      {
        id: 'fullName',
        header: 'Full Name',
        accessorKey: 'fullName',
      },
      {
        id: 'email',
        header: 'Email',
        accessorKey: 'email',
      },
      {
        id: 'status',
        header: 'Status',
        accessorKey: 'status',
        cell: (data) => {
          const status = data.cell.getValue<AuthorisedSignatoryStatus>();
          return <StatusField status={formatStatus(status)} label={status} />;
        },
      },
      {
        id: 'actions',
        header: 'Actions',
        accessorKey: 'actions',
        cell: (data) => {
          const attachment = data.row.original.attachment;
          const [isShowEditDialog, setIsShowEditDialog] = useState(false);
          const form = useForm<AuthorisedSignatory>({
            refineCoreProps: {
              resource: resourceNames.authorisedSignatory,
              action: 'edit',
              redirect: false,
              errorNotification: false,
              onMutationSuccess: () => setIsShowEditDialog(false),
              id: data.row.original.id,
              metaData: {
                fields: ['status'] as any[],
              },
            },
          });

          return (
            <div className="flex flex-row gap-2">
              {attachment && (
                <Dialog>
                  <DialogTrigger asChild>
                    <Button variant={'solid'} size="sm" className="gap-2">
                      <HiOutlineEye className="h-4 w-4" />
                      View
                    </Button>
                  </DialogTrigger>
                  <DialogContent>
                    <div className="flex justify-center items-center h-full">
                      <img src={attachment} alt="Attachment" className="max-w-full max-h-full" />
                    </div>
                  </DialogContent>
                </Dialog>
              )}
              <Dialog open={isShowEditDialog} onOpenChange={setIsShowEditDialog}>
                <DialogTrigger asChild>
                  <Button variant={'outline'} size="sm" className="gap-2">
                    <HiOutlinePencil className="w-4 h-4" /> Edit
                  </Button>
                </DialogTrigger>
                <DialogPortal>
                  <DialogOverlay />
                  <DialogContent>
                    <DialogTitle>Edit Authoriser</DialogTitle>
                    <div className="lg:min-w-full">
                      <div className="py-3">
                        <DynamicForm<AuthorisedSignatory>
                          form={form}
                          defaultValues={{ status: data.row.original.status }}
                          controls={{
                            components: {
                              status: {
                                type: 'select',
                                config: {
                                  label: 'Status',
                                  options: [
                                    { label: 'Active', value: AuthorisedSignatoryStatus.Active },
                                    { label: 'Revoked', value: AuthorisedSignatoryStatus.Revoked },
                                  ],
                                },
                              },
                            },
                          }}
                          submitText={false}
                          id={'edit-profile-consent-dialog'}
                          inferSections={false}
                          type={'edit'}
                        />
                      </div>
                      <div className="flex flex-row-reverse gap-3 pt-2 border-t">
                        <Button
                          type="submit"
                          variant="solid"
                          loading={form.refineCore.mutationResult.isLoading}
                          disabled={form.refineCore.formLoading}
                          form={'edit-profile-consent-dialog'}
                        >
                          Save
                        </Button>
                        <Button
                          onClick={() => setIsShowEditDialog(false)}
                          disabled={
                            form.refineCore.formLoading || form.refineCore.mutationResult.isLoading
                          }
                        >
                          Cancel
                        </Button>
                      </div>
                    </div>
                  </DialogContent>
                </DialogPortal>
              </Dialog>
            </div>
          );
        },
      },
    ],
    filterConfig: {
      permanent: [
        {
          field: 'companyProfileId' as any,
          operator: 'eq',
          value: profileId,
        },
        {
          field: 'creationStatus',
          operator: 'eq',
          value: CreationStatus.Approved,
        },
      ],
    },
  });

  return tableComponent();
};
