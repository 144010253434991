import * as React from 'react';

import { formatDate, StatusField } from '@amfintech/react-admin-ui';
import { LinkToDetails, ResourceTableConfig } from '@amfintech/refine-react-admin';

import { Profile, Transaction, TransactionStatus, TransactionType } from '~/api';
import { resourceNames } from '~/resources/resource-names';
import { formatTransactionStatus } from '~/resources/transaction-resource';

export const ProfileTransactionsPage = ({
  createTableComponent,
  profileId,
}: {
  createTableComponent: <T extends object>(props: ResourceTableConfig<T>) => () => React.ReactNode;
  profileId?: string;
}) => {
  const tableComponent = createTableComponent<Transaction>({
    resource: resourceNames.transaction,
    fields: [
      'id',
      'referenceNo',
      'transactionDatetime',
      'transactionType',
      { account: ['masterAccountNo'] },
      'amount',
      'transactionStatus',
      { fund: ['fundName'] },
    ],
    columns: () => [
      {
        id: 'referenceNo',
        header: 'Transaction Reference Number',
        accessorKey: 'referenceNo',
        cell: (data) => {
          const refNumber = data.cell.getValue<string>();
          return (
            <LinkToDetails
              resourceName={resourceNames.transaction}
              resourceId={data.row.original.id}
              className="font-semibold"
            >
              {refNumber}
            </LinkToDetails>
          );
        },
      },
      {
        id: 'transactionDatetime',
        header: 'Transaction Datetime',
        accessorKey: 'transactionDatetime',
        cell: (data) => {
          const datetime = data.cell.getValue<Date>();
          return formatDate(datetime, { formatType: 'dateAndTime' });
        },
      },
      {
        id: 'transactionType',
        header: 'Action',
        accessorKey: 'transactionType',
      },
      {
        id: 'masterAccountNo',
        header: 'Master Account Number',
        accessorKey: 'account.masterAccountNo',
      },
      {
        id: 'fundName',
        header: 'Fund Name',
        accessorKey: 'fund.fundName',
      },
      {
        id: 'amount',
        header: 'Amount',
        accessorKey: 'amount',
        cell: (data) => {
          const amount = data.cell.getValue<number>();
          return <>{amount?.toLocaleString('en-US', { style: 'currency', currency: 'MYR' })}</>;
        },
      },
      {
        id: 'transactionStatus',
        header: 'Transaction Status',
        accessorKey: 'transactionStatus',
        cell: (data) => {
          const status = data.cell.getValue<TransactionStatus>();
          return <StatusField status={formatTransactionStatus(status)} label={status} />;
        },
      },
    ],
    filterControls: {
      transactionType: {
        type: 'select',
        config: {
          label: 'Action',
          placeholder: 'Select action',
          options: Object.values(TransactionType).map((type) => ({
            label: type,
            value: type,
          })),
        },
        operator: 'eq',
      },
    },
    filterConfig: {
      permanent: [
        {
          field: '.account.is.profile.is.id' as any,
          operator: 'eq',
          value: profileId,
        },
      ],
    },
    allowSearch: false,
  });

  return tableComponent();
};
