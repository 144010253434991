import { createHelpers, createResource } from '@amfintech/refine-react-admin';

import { Payment } from '~/api';

import { resourceNames } from './resource-names';

const { defineFields, defineShowPage, defineCardSection } = createHelpers<Payment>({
  resourceName: resourceNames.payment,
});

export const paymentResource = createResource({
  name: resourceNames.payment,
  label: 'Payment',
  meta: {
    hide: true,
  },
  fields: defineFields([
    'id',
    'referenceNo',
    'externalReferenceNo',
    'amount',
    'currency',
    'transactedAt',
    'paymentMethod',
    'paymentProof',
    'status',
    'orderId',
  ]),
  defaultValues: {},
  columns: () => [],
  show: defineShowPage({
    sections: [
      defineCardSection({
        title: 'Details',
        fields: [
          'referenceNo',
          'externalReferenceNo',
          'amount',
          'currency',
          'transactedAt',
          'paymentMethod',
          'paymentProof',
          'status',
          'orderId',
        ],
      }),
    ],
  }),
});
