import { Button, StatusField } from '@amfintech/react-admin-ui';
import {
  CreateDataInput,
  CreateResourceEditDialogReturns,
  CreateResourceEditPageOptions,
  ResourceTableConfig,
  sentenceCase,
} from '@amfintech/refine-react-admin';
import dayjs from 'dayjs';

import { ModificationEntityType, Status, User } from '~/api';
import { useSendInvitationCodeMutation } from '~/hooks/user-send-invitation-code';
import { formatUserStatus } from '~/resources/customer-resource';
import { resourceNames } from '~/resources/resource-names';

export const ProfileMaintenancePage = ({
  createTableComponent,
  createResourceEditDialog,
  profileId,
}: {
  createTableComponent: <T extends object>(props: ResourceTableConfig<T>) => () => React.ReactNode;
  createResourceEditDialog: <T extends object, DefaultValues extends CreateDataInput<T>>(
    options: CreateResourceEditPageOptions<T, DefaultValues>
  ) => CreateResourceEditDialogReturns;
  profileId?: string;
}) => {
  const { mutate: sendInvitationCode } = useSendInvitationCodeMutation();

  const handleGenerateInvitationCode = async (userId: string) => {
    sendInvitationCode(userId);
  };

  const tableComponent = createTableComponent<User>({
    resource: resourceNames.user,
    fields: [
      'id',
      'name',
      'username',
      'email',
      'phoneNo',
      'hpPhoneNo',
      'status',
      'updatedAt',
      'invitationCode',
      'invitationSentAt',
    ],
    columns: () => [
      {
        field: 'username',
        header: 'User ID',
        accessorKey: 'username',
      },
      {
        field: 'name',
        header: 'Full Name',
        accessorKey: 'name',
      },
      {
        field: 'email',
        header: 'Email',
        accessorKey: 'email',
      },
      {
        field: 'phoneNo',
        header: 'Office Phone No',
        accessorKey: 'phoneNo',
      },
      {
        field: 'hpPhoneNo',
        header: 'HP Phone No',
        accessorKey: 'hpPhoneNo',
      },
      {
        field: 'status',
        header: 'Status',
        accessorKey: 'status',
        cell: (data) => {
          const status = data.cell.getValue<Status>();
          return <StatusField status={formatUserStatus(status)} label={status} />;
        },
      },
      // {
      //   field: 'updatedAt',
      //   header: 'Last Modified',
      //   accessorKey: 'updatedAt',
      //   cell: ({ row }) => dayjs(row.original.updatedAt).format('DD MMM YYYY HH:mm:ssa'),
      // },
      {
        field: 'action',
        header: 'Invitation Code',
        cell: (data) => {
          // Check user status is ACTIVE, do not show invitation
          if (data.row.original.status === Status.Active) {
            return null;
          }

          // Check if invitation has been sent
          if (data.row.original.invitationSentAt) {
            return (
              <>
                <div>{data.row.original.invitationCode} </div>
                <p className="text-sm text-muted-foreground">
                  Sent at{' '}
                  {dayjs(data.row.original.invitationSentAt).format('DD MMM YYYY HH:mm:ssa')}
                </p>
              </>
            );
          }

          const userId = data.row.original.id;

          if (!userId) return null;

          return (
            <Button
              variant="solid"
              size="sm"
              className="min-w-[6rem]"
              onClick={() => handleGenerateInvitationCode(userId)}
            >
              Invitation Code
            </Button>
          );
        },
      },
      {
        field: 'action',
        header: 'Action',
        cell: (data) => {
          const EditButton = createResourceEditDialog<User, CreateDataInput<User>>({
            resourceName: resourceNames.user,
            fields: ['id', 'username', 'email', 'name', 'phoneNo', 'hpPhoneNo', 'status'],
            defaultValues: {
              email: data.row.original.email,
              name: data.row.original.name,
              phoneNo: data.row.original.phoneNo,
              hpPhoneNo: data.row.original.hpPhoneNo,
              status: data.row.original.status,
            },
            controls: {
              components: {
                email: {
                  type: 'text',
                  config: {
                    label: 'Email',
                    disabled: true,
                  },
                },
                name: {
                  type: 'text',
                  config: {
                    label: 'Full Name',
                  },
                },
                phoneNo: {
                  type: 'text',
                  config: {
                    label: 'Office Phone No',
                  },
                },
                hpPhoneNo: {
                  type: 'text',
                  config: {
                    label: 'HP Phone No',
                  },
                },
                status: {
                  type: 'select',
                  config: {
                    label: 'Status',
                    options: Object.values(Status).map((status) => ({
                      label: sentenceCase(status),
                      value: status,
                    })),
                  },
                },
              },
            },
            makerChecker: {
              entityType: ModificationEntityType.User,
            },
          }).Button;
          return (
            <EditButton
              resourceId={data.row.original.id}
              variant="solid"
              size="sm"
              className="min-w-[6rem]"
            />
          );
        },
      },
    ],
    filterControls: {
      email: {
        type: 'text',
        config: {
          label: 'Email',
        },
        operator: 'contains',
      },
    },
    filterConfig: {
      permanent: [
        {
          field: '.profiles.some.id' as any,
          operator: 'eq',
          value: profileId,
        },
      ],
    },
  });

  return tableComponent();
};
