import { formatDate } from '@amfintech/react-admin-ui';
import { createHelpers, createResource } from '@amfintech/refine-react-admin';

import { ModificationEntityType, PublicHoliday } from '~/api';

import { resourceNames } from './resource-names';

const { defineFields, defineShowPage, defineCardSection } = createHelpers<PublicHoliday>({
  resourceName: resourceNames.publicHoliday,
});

export const publicHolidayResource = createResource({
  name: resourceNames.publicHoliday,
  label: 'Public Holiday',
  fields: defineFields(['id', 'name', 'startDate', 'endDate', 'createdAt', 'updatedAt']),
  defaultValues: {},
  allowDownload: true,
  list: {
    filters: {
      collapsible: 'expand-by-default',
    },
    makerChecker: {
      entityTypes: [ModificationEntityType.PublicHoliday],
    },
    downloadConfig: {
      startDate: (value: any) => formatDate(value, { formatType: 'dateOnly' }),
      endDate: (value: any) => formatDate(value, { formatType: 'dateOnly' }),
    },
  },
  filterControls: {
    name: {
      type: 'text',
      config: {
        label: 'Holiday Name',
      },
      operator: 'contains',
    },
    startDate: {
      type: 'date',
      config: {
        label: 'Start Date',
      },
      operator: 'gte',
    },
    endDate: {
      type: 'date',
      config: {
        label: 'End Date',
      },
      operator: 'lte',
    },
  },
  defaultSorter: [
    {
      field: 'startDate',
      order: 'asc',
    },
  ],
  controls: {
    components: {
      name: {
        type: 'text',
        config: {
          label: 'Holiday Name',
        },
      },
      startDate: {
        type: 'date',
        config: {
          label: 'Start Date',
        },
      },
      endDate: {
        type: 'date',
        config: {
          label: 'End Date',
        },
      },
    },
  },
  columns: ({ LinkToDetails }) => [
    {
      id: 'name',
      header: 'Holiday Name',
      accessorKey: 'name',
      cell: (data) => (
        <LinkToDetails resourceId={data.row.original.id} className="font-semibold">
          {data.getValue<string>()}
        </LinkToDetails>
      ),
    },
    {
      id: 'startDate',
      header: 'Start Date',
      accessorKey: 'startDate',
      cell: (data) => formatDate(data.getValue<string>(), { formatType: 'dateOnly' }),
    },
    {
      id: 'endDate',
      header: 'End Date',
      accessorKey: 'endDate',
      cell: (data) => formatDate(data.getValue<string>(), { formatType: 'dateOnly' }),
    },
  ],
  show: defineShowPage({
    sections: [
      defineCardSection({
        title: 'Details',
        fields: ['name', 'startDate', 'endDate', 'createdAt', 'updatedAt'],
      }),
    ],
  }),
});
