import { Card, CardContent, Switch } from '@amfintech/react-admin-ui';
import { sentenceCase } from 'change-case';

import { Profile } from '~/api';

export const ProfileInfoPage = ({ profile }: { profile?: Profile }) => {
  if (!profile) return null;

  const companyDetails = {
    MasterAccountNumber: profile.accounts
      ? profile.accounts
          .map((account: { masterAccountNo: string }) => account.masterAccountNo)
          .join(', ')
      : '-',
    BusinessCategory: profile.businessCategoryDisplay,
    DateOfRegistry: profile.createdAt,
    CompanyOfficeAddress: profile.companyAddress,
    MailingAddress: profile.mailingAddress,
    City: profile.city,
    State: profile.state,
    Postcode: profile.postcode,
  };

  const investmentAccountDetails = {
    MarketingIndicator: profile.marketingPush,
    // ApplicationDate: profile.appplicationDate,
    HighNetWorthIndicator: profile.highNetWorth,
    PrimaryMobileNumber: profile.phoneNo,
    BusinessEmail: profile.email,
    BusinessNature: profile.natureOfBusiness,
    SourceOfFund: profile.sourceOfFund,
    SourceOfWealth: profile.sourceOfWealth,
  };

  const bankDetails = profile.bankAccounts?.find((account) => account.isDefault)
    ? {
        BankCode: profile.bankAccounts.find((account) => account.isDefault)?.bankCode,
        BeneficiaryName: profile.bankAccounts.find((account) => account.isDefault)?.beneficiaryName,
        BankAccountNumber: profile.bankAccounts.find((account) => account.isDefault)?.accountNo,
      }
    : { BankCode: '-', BeneficiaryName: '-', BankAccountNumber: '-' };

  return (
    <div className="space-y-4">
      {/* Company Details */}
      <Card>
        <CardContent className="p-4">
          <h3 className="text-lg font-semibold mb-4">Company Details</h3>
          <div className="grid grid-cols-2 gap-4">
            {Object.entries(companyDetails).map(([key, value]) => (
              <div key={key}>
                <span className="block text-sm font-medium text-gray-700 mb-1">{sentenceCase(key)}</span>
                <p className="text-sm text-gray-600">{value?.toString() ?? '-'}</p>
              </div>
            ))}
          </div>
        </CardContent>
      </Card>

      {/* Investment Account Details */}
      <Card>
        <CardContent className="p-4">
          <h3 className="text-lg font-semibold mb-4">Investment Account Details</h3>
          <div className="grid grid-cols-2 gap-4">
            {Object.entries(investmentAccountDetails).map(([key, value]) => (
              <div key={key}>
                <span className="block text-sm font-medium text-gray-700 mb-1">{sentenceCase(key)}</span>
                {key === 'HighNetWorthIndicator' || key === 'MarketingIndicator' ? (
                  <Switch checked={value === true} disabled />
                ) : (
                  <p className="text-sm text-gray-600">{value?.toString() ?? '-'}</p>
                )}
              </div>
            ))}
          </div>
        </CardContent>
      </Card>

      {/* Bank Details */}
      <Card>
        <CardContent className="p-4">
          <h3 className="text-lg font-semibold mb-4">Bank Details</h3>
          <div className="grid grid-cols-2 gap-4">
            <div>
              <span className="block text-sm font-medium text-gray-700 mb-1">Bank code</span>
              <p className="text-sm text-gray-600">{bankDetails.BankCode}</p>
            </div>
            <div>
              <span className="block text-sm font-medium text-gray-700 mb-1">
                Bank account number
              </span>
              <p className="text-sm text-gray-600">{bankDetails.BankAccountNumber}</p>
            </div>
            <div>
              <span className="block text-sm font-medium text-gray-700 mb-1">Beneficiary name</span>
              <p className="text-sm text-gray-600">{bankDetails.BeneficiaryName}</p>
            </div>
          </div>
        </CardContent>
      </Card>
    </div>
  );
};
