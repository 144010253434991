import { formatDate } from '@amfintech/react-admin-ui';
import { createHelpers, createResource } from '@amfintech/refine-react-admin';

import { FundHoliday, ModificationEntityType } from '~/api';

import { resourceNames } from './resource-names';

const { defineFields, defineShowPage, defineResourceSelect, defineCardSection } =
  createHelpers<FundHoliday>({
    resourceName: resourceNames.fundHoliday,
  });

export const fundHolidayResource = createResource({
  name: resourceNames.fundHoliday,
  label: 'Fund Holiday',
  fields: defineFields([
    'id',
    'fundId',
    'startDate',
    'endDate',
    {
      fund: ['id', 'fundName', 'fundCode'],
    },
  ]),
  allowDownload: true,
  list: {
    downloadConfig: {
      startDate: (value: string) => formatDate(value, { formatType: 'dateOnly' }),
      endDate: (value: string) => formatDate(value, { formatType: 'dateOnly' }),
    },
    filters: {
      collapsible: 'expand-by-default',
    },
    makerChecker: {
      entityTypes: [ModificationEntityType.FundHoliday],
    },
  },
  defaultValues: {},
  controls: {
    components: {
      fundId: defineResourceSelect({
        label: 'Fund Name',
        fields: ['fundName', 'id'],
        resourceName: resourceNames.fund,
        optionLabel: 'fundName',
        optionValue: 'id',
      }),
      startDate: {
        type: 'date',
        config: {
          label: 'Start Date',
        },
      },
      endDate: {
        type: 'date',
        config: {
          label: 'End Date',
        },
      },
    },
  },
  filterControls: {
    fundId: {
      type: 'resource-select',
      config: {
        label: 'Fund Name',
        fields: ['fundName', 'id'],
        resourceName: resourceNames.fund,
        optionLabel: 'fundName',
        optionValue: 'id',
        options: [], // This gets populated by the useResourceSelect hook
      },
    },
    startDate: {
      type: 'date',
      config: {
        label: 'Start Date',
      },
      operator: 'gte',
    },
    endDate: {
      type: 'date',
      config: {
        label: 'End Date',
      },
      operator: 'lte',
    },
  },
  columns: ({ LinkToDetails }) => [
    {
      id: 'fund.fundName',
      header: 'Fund Name',
      accessorKey: 'fund.fundName',
      cell: (data) => (
        <LinkToDetails resourceId={data.row.original.id} className="font-semibold">
          {data.getValue<string>()}
        </LinkToDetails>
      ),
    },
    {
      id: 'fund.fundCode',
      header: 'Fund Code',
      accessorKey: 'fund.fundCode',
    },
    {
      id: 'startDate',
      header: 'Start Date',
      accessorKey: 'startDate',
      cell: (data) => formatDate(data.getValue<string>(), { formatType: 'dateOnly' }),
    },
    {
      id: 'endDate',
      header: 'End Date',
      accessorKey: 'endDate',
      cell: (data) => formatDate(data.getValue<string>(), { formatType: 'dateOnly' }),
    },
  ],
  show: defineShowPage({
    sections: [
      defineCardSection({
        title: 'Details',
        fields: [
          {
            fund: ['fundName', 'fundCode'],
          },
          'startDate',
          'endDate',
          'createdAt',
          'updatedAt',
        ],
        displays: {
          'fund.fundName': {
            label: 'Fund Name',
            render: (data, modifications) => {
              if (!modifications) {
                return data.fund?.fundName;
              }

              return (
                <modifications.ModificationDiff
                  oldValue={modifications.oldValues?.fundName}
                  newValue={modifications.newValues?.fundName}
                  getModifiedResource={{
                    resourceName: resourceNames.fund,
                    id: modifications.newValues?.fundId,
                    field: 'fundName',
                  }}
                />
              );
            },
          },
          'fund.fundCode': {
            label: 'Fund Code',
            render: (data, modifications) => {
              if (!modifications) {
                return <>{data.fund?.fundCode}</>;
              }

              return (
                <modifications.ModificationDiff
                  oldValue={modifications.oldValues?.fundCode}
                  newValue={modifications.newValues?.fundCode}
                  getModifiedResource={{
                    resourceName: resourceNames.fund,
                    id: modifications.newValues?.fundId,
                    field: 'fundCode',
                  }}
                />
              );
            },
          },
        },
      }),
    ],
  }),
});
