import { useState } from 'react';
import { HiOutlineEye, HiOutlinePencil } from 'react-icons/hi';

import {
  AlertDialog,
  Badge,
  Button,
  Dialog,
  DialogContent,
  DialogOverlay,
  DialogPortal,
  DialogTitle,
  DialogTrigger,
  StatusField,
} from '@amfintech/react-admin-ui';
import { DynamicForm, ResourceTableConfig } from '@amfintech/refine-react-admin';
import { useForm } from '@refinedev/react-hook-form';

import { ConsentStatus, CreationStatus, ProfileConsent } from '~/api';
import { resourceNames } from '~/resources/resource-names';

const formatStatus = (status: ConsentStatus) => {
  switch (status) {
    case ConsentStatus.Active:
      return 'success';
    case ConsentStatus.Revoked:
      return 'danger';
    default:
      return 'pending';
  }
};

export const ProfileConsentPage = ({
  createTableComponent,
  profileId,
}: {
  createTableComponent: <T extends object>(props: ResourceTableConfig<T>) => () => React.ReactNode;
  profileId?: string;
}) => {
  const tableComponent = createTableComponent<ProfileConsent>({
    resource: resourceNames.profileConsent,
    fields: [
      'id',
      'consentType',
      'approvedEmail',
      'updatedAt',
      { issuerProfile: ['id', 'fullName', 'companyName', 'idNo', 'email', 'type'] },
      { recipientProfile: ['id', 'fullName', 'companyName', 'idNo', 'email', 'type'] },
      'status',
    ],
    columns: () => [
      {
        id: 'issuerProfile.companyName',
        header: 'Issuer Name',
        accessorKey: 'issuerProfile.companyName',
      },
      {
        id: 'issuerProfile.idNo',
        header: 'Issuer ID No',
        accessorKey: 'issuerProfile.idNo',
      },
      {
        id: 'email',
        header: 'Dedicated Email Address',
        accessorKey: 'approvedEmail',
      },
      {
        id: 'consentType',
        header: 'Consent Type',
        accessorKey: 'consentType',
      },
      {
        id: 'status',
        header: 'Status',
        accessorKey: 'status',
        cell: (data) => {
          const status = data.cell.getValue<ConsentStatus>();
          return <StatusField status={formatStatus(status)} label={status} />;
        },
      },
      {
        id: 'action',
        header: 'Action',
        accessorKey: 'action',
        cell: (data) => {
          const status = data.row.original.status;
          const [isShowEditDialog, setIsShowEditDialog] = useState(false);
          const form = useForm<ProfileConsent>({
            refineCoreProps: {
              resource: resourceNames.profileConsent,
              action: 'edit',
              redirect: false,
              errorNotification: false,
              onMutationSuccess: () => setIsShowEditDialog(false),
              id: data.row.original.id,
              metaData: {
                fields: ['status'] as any[],
              },
            },
          });

          return (
            <Dialog open={isShowEditDialog} onOpenChange={setIsShowEditDialog}>
              <DialogTrigger asChild>
                <Button variant={'outline'} size="sm" className="gap-2">
                  <HiOutlinePencil className="w-4 h-4" /> Edit
                </Button>
              </DialogTrigger>
              <DialogPortal>
                <DialogOverlay />
                <DialogContent>
                  <DialogTitle>Edit Consent</DialogTitle>
                  <div className="lg:min-w-full">
                    <div className="py-3">
                      <DynamicForm<ProfileConsent>
                        form={form}
                        defaultValues={{ status }}
                        controls={{
                          components: {
                            status: {
                              type: 'select',
                              config: {
                                label: 'Status',
                                options: [
                                  { label: 'Active', value: ConsentStatus.Active },
                                  { label: 'Revoked', value: ConsentStatus.Revoked },
                                ],
                              },
                            },
                          },
                        }}
                        submitText={false}
                        id={'edit-profile-consent-dialog'}
                        inferSections={false}
                        type={'edit'}
                      />
                    </div>
                    <div className="flex flex-row-reverse gap-3 pt-2 border-t">
                      <Button
                        type="submit"
                        variant="solid"
                        loading={form.refineCore.mutationResult.isLoading}
                        disabled={form.refineCore.formLoading}
                        form={'edit-profile-consent-dialog'}
                      >
                        Save
                      </Button>
                      <Button
                        onClick={() => setIsShowEditDialog(false)}
                        disabled={
                          form.refineCore.formLoading || form.refineCore.mutationResult.isLoading
                        }
                      >
                        Cancel
                      </Button>
                    </div>
                  </div>
                </DialogContent>
              </DialogPortal>
            </Dialog>
          );
        },
      },
    ],
    filterConfig: {
      permanent: [
        {
          field: 'recipientProfileId' as any,
          operator: 'eq',
          value: profileId,
        },
        {
          field: 'creationStatus',
          operator: 'eq',
          value: CreationStatus.Approved,
        },
      ],
    },
  });

  return tableComponent();
};
