import ReactDOM from 'react-dom/client';
import { FiCommand } from 'react-icons/fi';

import { assertExists } from '@amfintech/react-admin-ui';
import {
  createRefineAdminApp,
  defineCustomPage,
  defineDataProvider,
  groupResources,
} from '@amfintech/refine-react-admin';

import '@amfintech/refine-react-admin/styles.css';

import 'tailwindcss/tailwind.css';

import type { AdminUser } from './api';
import { getSdk } from './api';
import logo from './assets/logo.png';
import Dashboard from './pages/dashboard';
import { accountOpeningResource } from './resources/account-opening-resource';
import { adminAuditResource } from './resources/admin-audit-resource';
import { adminRoleResource } from './resources/admin-role-resource';
import { adminUserResource } from './resources/admin-user-resource';
import { announcementResource } from './resources/announcement-resource';
import { collectionBankAccountResource } from './resources/collection-bank-account-resource';
import { customerAuditResource } from './resources/customer-audit-resource';
import { customerResource } from './resources/customer-resource';
import { developerToolsResource } from './resources/developer-tools-resource';
import { documentResource } from './resources/document-resource';
import { fundDocumentResource } from './resources/fund-document-resource';
import { fundHolidayResource } from './resources/fund-holiday-resource';
import { fundInfoResource } from './resources/fund-info-resource';
import { fundPolicyResource } from './resources/fund-policy-resource';
import { leadGenerationResource } from './resources/lead-generation-resource';
import { orderResource } from './resources/order-resource';
import { paymentResource } from './resources/payment-resource';
import { profileConsentResource } from './resources/profile-consent-resource';
import { profileSignatoriesResource } from './resources/profile-signatories-resource';
import { publicHolidayResource } from './resources/public-holiday-resource';
import { resourceNames } from './resources/resource-names';
import { signatoryResource } from './resources/signatory-resource';
import { tomswinSyncResource } from './resources/tomswin-sync-resource';
import { transactionResource } from './resources/transaction-resource';
import { trusteeResource } from './resources/trustee-resource';
import { userResource } from './resources/user-resource';

type AdminUserVariables = {
  name: string;
  email: string;
  username: string;
  roleId: string;
};

const RefineApp = createRefineAdminApp({
  graphqlUrl: `${import.meta.env.VITE_API_BASE_URL}/graphql`,
  useUncheckedScalarInputs: true,
  sidebarHeader: (
    <div className="px-16 pb-3">
      <img src={logo} alt="FMD Middle Back Office" />
    </div>
  ),
  loginHeader: (
    <div className="flex flex-col items-center gap-3 pb-3">
      <img src={logo} />
      <h1 className="text-2xl text-center font-bold"></h1>
    </div>
  ),
  loginBackground: '/login-bg.jpg',
  supportForgotPassword: false,
  design: {
    sidebarVariant: 'gray',
  },
  DashboardPage: defineCustomPage({
    title: 'Dashboard',
    render: () => <Dashboard />,
  }),
  resources: [
    ...groupResources({
      groupName: 'administration',
      icon: <FiCommand />,
      label: 'Administration',
      resources: [adminUserResource, adminRoleResource],
    }),
    userResource,
    paymentResource,
    signatoryResource,
    documentResource,
    fundDocumentResource,
    fundPolicyResource,
    collectionBankAccountResource,
    trusteeResource,
    profileConsentResource,
    profileSignatoriesResource,
    customerResource,
    ...groupResources({
      groupName: 'maintenance',
      icon: <FiCommand />,
      label: 'Maintenance',
      resources: [
        fundInfoResource,
        fundHolidayResource,
        publicHolidayResource,
        announcementResource,
      ],
    }),
    ...groupResources({
      groupName: 'report',
      icon: <FiCommand />,
      label: 'Reports',
      resources: [
        orderResource,
        transactionResource,
        leadGenerationResource,
        accountOpeningResource,
      ],
    }),
    ...groupResources({
      groupName: 'auditTrail',
      icon: <FiCommand />,
      label: 'Audit Trails',
      resources: [adminAuditResource, customerAuditResource],
    }),
    ...groupResources({
      groupName: 'developerTools',
      icon: <FiCommand />,
      label: 'Developer Tools',
      resources: [developerToolsResource, tomswinSyncResource],
    }),
  ],
  resourceDataProvider: new Map([
    [
      resourceNames.adminUser,
      defineDataProvider<AdminUser>({
        create: ({ client, variables }) =>
          getSdk(client)
            .createAdminUser({
              data: variables as AdminUserVariables,
            })
            .then((res) => ({
              data: res.createOneAdminUser,
            })),
        update: ({ client, variables, id }) =>
          getSdk(client)
            .updateAdminUser({
              data: variables as AdminUserVariables,
              where: {
                id: id as string,
              },
            })
            .then((res) => {
              assertExists(
                res.updateOneAdminUser,
                'value returned by updateOneAdminUser is undefined'
              );

              return {
                data: res.updateOneAdminUser,
              };
            }),
      }),
    ],
  ]),
});

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(<RefineApp />);
