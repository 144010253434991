import { FiEdit } from 'react-icons/fi';

import { Button, StatusField } from '@amfintech/react-admin-ui';
import { createHelpers, createResource } from '@amfintech/refine-react-admin';

import { AdminRole, ModificationEntityType, Status, type AdminUser } from '~/api';
import { RoleList } from '~/pages/edit-role/role-list';

import { resourceNames } from './resource-names';

const { defineFields, defineCardSection, defineShowPage, defineRelatedCardSection } = createHelpers<AdminUser>({
  resourceName: resourceNames.adminUser,
});

const defaultValues = {
  name: '',
  email: '',
  username: '',
  roleId: undefined,
};

export const adminUserResource = createResource({
  name: resourceNames.adminUser,
  label: 'Backoffice Users',
  fields: defineFields([
    'id',
    'username',
    'name',
    'email',
    'status',
    'roleId',
    { role: ['name'] },
    'createdAt',
    'updatedAt',
  ]),
  defaultValues,
  formatBeforeSubmit: (data) => ({
    ...data,
    status: Status.Active,
  }),
  controls: {
    sections: [
      {
        title: 'General',
        items: ['username', 'name', 'email'],
      },
      {
        title: 'User Group',
        items: ['roleId'],
      },
    ],
    components: {
      username: {
        type: 'text',
        config: {
          label: 'LAN ID',
        },
      },
      email: {
        type: 'text',
        config: {
          label: 'Email',
          type: 'email',
        },
      },
      name: {
        type: 'text',
        config: {
          label: 'Full Name',
        },
      },
      roleId: {
        type: 'resource-select',
        config: {
          label: 'User Group',
          resourceName: resourceNames.adminRole,
          optionLabel: 'name',
          optionValue: 'id',
          fields: ['id', 'name'],
        },
      },
    },
    componentConfigDefaults: {
      required: true,
    },
  },
  list: {
    makerChecker: {
      entityTypes: [ModificationEntityType.AdminUser],
    },
  },
  defaultPageSize: 10,
  defaultSorter: [{ field: 'createdAt', order: 'desc' }],
  allowCreate: true,
  allowEdit: true,
  allowSearch: true,
  allowDelete: true,
  columns: ({ LinkToDetails, navigateToEdit, allowToEdit }) => [
    {
      id: 'username',
      header: 'LAN ID',
      accessorKey: 'username',
      cell: (data) => {
        const username = data.cell.getValue<string>();

        return (
          <LinkToDetails resourceId={data.row.original.id} className="font-semibold">
            {username}
          </LinkToDetails>
        );
      },
    },
    {
      id: 'name',
      header: 'Full Name',
      accessorKey: 'name',
    },
    {
      id: 'email',
      header: 'Email Address',
      accessorKey: 'email',
    },
    {
      id: 'role',
      header: 'User Group',
      accessorKey: 'role.name',
    },
    {
      id: 'status',
      header: 'Status',
      accessorKey: 'status',
      enableSorting: false,
      cell: (data) => {
        const status = data.cell.getValue<string>();
        return <StatusField status={status === 'Active' ? 'success' : 'danger'} label={status} />;
      },
    },
    {
      id: 'actions',
      header: () => <div className="text-right">Actions</div>,
      accessorKey: 'id',
      enableSorting: false,
      cell: (data) => {
        const userId = data.cell.getValue<string>();
        return (
          <span className="flex justify-end items-center gap-2">
            <Button
              variant={'ghost'}
              onClick={() => navigateToEdit({ id: userId })}
              disabled={!allowToEdit}
              className="text-primary-500 text-sm font-bold disabled:text-zinc-400"
              type="button"
            >
              <FiEdit />
            </Button>
          </span>
        );
      },
    },
  ],
  show: defineShowPage({
    sections: [
      defineCardSection({
        title: 'General',
        fields: ['name', 'email', 'username', 'status', { role: ['name'] }],
        collapsible: 'expand-by-default',
        displays: {
          'role.name': {
            label: 'User Group',
            render: (data, modifications) => {
              if (!modifications) {
                return <>{data.role?.name}</>;
              }

              return (
                <modifications.ModificationDiff
                  oldValue={modifications.oldValues.roleId}
                  newValue={modifications.newValues.roleId}
                  getModifiedResource={{
                    resourceName: resourceNames.adminRole,
                    id: modifications.newValues.roleId,
                    field: 'name',
                  }}
                />
              );
            },
          },
        },
      }),
      defineRelatedCardSection<AdminRole>({
        title: 'User Group',
        fields: [{ role: ['id'] }],
        relatedResourceName: resourceNames.adminRole,
        relatedResourceFields: ['name', 'description', 'permissions'],
        relatedResourceFieldName: 'role',
        displays: {
          permissions: {
            label: 'Applied Permissions',
            span: 2,
            render: (resource) => {
              const appliedPermissions = new Set(resource.permissions);

              return (
                <>
                  {appliedPermissions.size > 0 ? (
                    <RoleList
                      appliedPermissions={appliedPermissions}
                      handleAccessChange={() => {}}
                      disabled
                      onlyShowAppliedPermissions
                    />
                  ) : (
                    <div className="text-left text-black text-sm">No permissions applied</div>
                  )}
                </>
              );
            },
          },
        },
      }),
    ],
  }),
});
